import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import supabase from "../config/supabaseClient";
import default_profile from "../assets/images/default_profile.svg";

function Chat({ userId }) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [userConnection, setUserConnection] = useState([]);
  const [userProfilePic, setUserProfilePic] = useState({}); // Changed to an object to store profile pictures
  const [userMap, setUserMap] = useState({});
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [connectionId, setConnectionId] = useState(null);
  const [userType, setUserType] = useState(null); // Stores user type
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchUserTypeAndConnections = async () => {
      // Fetch the current user's type
      const { data: currentUser, error: userError } = await supabase
        .from("users")
        .select("type")
        .eq("user_id", userId)
        .single();

      if (userError) {
        console.error("Error fetching user type:", userError);
        return;
      }

      setUserType(currentUser.type);

      // Fetch connections based on user type
      const { data: connections, error: connectionsError } = await supabase
        .from("connection")
        .select("id, mentor, mentee, status, channel_chat, message_note")
        .eq(
          currentUser.type === "Mentee" ? "mentee" : "mentor",
          userId.toString()
        )
        .order("status", { ascending: false });

      if (connectionsError) {
        console.error("Error fetching connections:", connectionsError);
        return;
      }

      setUserConnection(connections || []);
      setSelectedConnection(connections[0] || null);

      // Determine the IDs to fetch based on user type
      const relatedUserIds = connections
        .flatMap((conn) => [conn.mentor, conn.mentee])
        .filter((id, index, self) => self.indexOf(id) === index);

      // Fetch user details for the other party
      const { data: users, error: userDetailsError } = await supabase
        .from("users")
        .select("user_id, name, type, profile_pic")
        .in("user_id", relatedUserIds);

      if (userDetailsError) {
        console.error("Error fetching users:", userDetailsError);
        return;
      }

      const newUserProfilePic = Object.fromEntries(
        users.map((user) => [user.user_id, user.profile_pic || default_profile]) // Use default profile if none exists
      );
      setUserProfilePic(newUserProfilePic);

      const newUserMap = Object.fromEntries(
        users.map((user) => [user.user_id, user.name])
      );
      setUserMap(newUserMap);

      // Set the connection ID for real-time messaging
      if (connections.length > 0) {
        setConnectionId(connections[0].id);
      }
    };

    fetchUserTypeAndConnections();
  }, [userId]);

  useEffect(() => {
    if (!connectionId) return;

    const fetchMessages = async () => {
      const { data: existingMessages, error } = await supabase
        .from("chat_messages")
        .select("*")
        .eq("connection_id", connectionId)
        .order("created_at", { ascending: true });

      if (error) {
        console.error("Error fetching messages:", error);
        return;
      }

      setMessages(existingMessages || []);
      scrollToBottom();
    };

    fetchMessages();

    // Create or connect to a real-time channel
    const channel = supabase.channel(
      `public:chat_messages:connection_id=eq.${connectionId}`
    );

    channel.on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "chat_messages",
        filter: `connection_id=eq.${connectionId}`,
      },
      (payload) => {
        const newMessage = payload.new;
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
    );

    channel.subscribe((status) => {
      if (status === "SUBSCRIBED") {
        console.log("Real-time subscription established.");
      }
    });

    return () => {
      supabase.removeChannel(channel);
    };
  }, [connectionId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    const messageContainer = document.querySelector(
      ".flex-1.overflow-y-auto.p-4"
    );
    messageContainer?.scrollTo({
      top: messageContainer.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === "" || !connectionId) return;

    // Determine receiver ID based on user type
    const receiverId =
      userType === "Mentee"
        ? selectedConnection.mentor
        : selectedConnection.mentee;

    const { error } = await supabase.from("chat_messages").insert([
      {
        connection_id: connectionId,
        sender_id: userId,
        receiver_id: receiverId,
        message: newMessage,
        created_at: new Date().toISOString(),
      },
    ]);

    if (error) {
      console.error("Error sending message:", error);
    } else {
      setNewMessage("");
    }
  };

  const handleAccept = async () => {
    if (!selectedConnection) return;

    const { error } = await supabase
      .from("connection")
      .update({ status: "Accepted" })
      .eq("id", selectedConnection.id);

    if (error) {
      console.error("Error accepting connection:", error);
    } else {
      setSelectedConnection((prev) => ({ ...prev, status: "Accepted" }));
    }
  };

  const handleReject = async (connectionId) => {
    const { error } = await supabase
      .from("connection")
      .update({ status: "Rejected" })
      .eq("id", connectionId);

    if (error) {
      console.error("Error rejecting connection:", error);
    } else {
      setSelectedConnection((prev) => ({ ...prev, status: "Rejected" }));
    }
  };

  // Helper function to format dates
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) return "Today";
    if (date.toDateString() === yesterday.toDateString()) return "Yesterday";
    return date.toLocaleDateString();
  };

  // Group messages by date
  const groupedMessages = messages.reduce((groups, message) => {
    const date = formatDate(message.created_at);
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(message);
    return groups;
  }, {});

  const handleConnectionChange = (connection) => {
    setSelectedConnection(connection);
    setConnectionId(connection.id);
  };

  return (
    <div className="pt-20 py-4">
      {userConnection.length > 0 ? (
        <div className="mx-4 md:mx-20">
          <div className="text-xl font-bold flex justify-center text-center my-4 ">
            Your Messages
          </div>
          <div className="flex flex-col md:flex-row justify-center h-full">
            {/* Sidebar */}
            <div className="md:w-1/4 bg-stone-200 h-full rounded-md overflow-y-auto mb-4">
              {userConnection.map((connection) => {
                const otherUserId =
                  userType === "Mentee" ? connection.mentor : connection.mentee;
                return (
                  <div
                    key={connection.id}
                    className={`flex border border-spacing-1 border-white px-4 py-2 cursor-pointer font-semibold ${
                      selectedConnection?.id === connection.id
                        ? "bg-secondary-500 bg-opacity-50"
                        : "hover:bg-gray-100"
                    }`}
                    onClick={() => handleConnectionChange(connection)}
                  >
                    {userMap[otherUserId]}{" "}
                    <span className="px-2 flex items-center text-sm font-light">
                      ({connection.status})
                    </span>
                  </div>
                );
              })}
            </div>
            {/* Main Chat Area */}
            <div className="md:w-3/4 flex flex-col h-[60lvh] border border-white bg-secondary-500 bg-opacity-50 rounded-md mx-2 px-2 overflow-y-auto">
              {selectedConnection ? (
                <>
                  <div className="flex items-center justify-center border-b-2 border-white py-2 font-semibold">
                    <Link
                      to={`/profile/${
                        userType === "Mentee"
                          ? selectedConnection.mentor
                          : selectedConnection.mentee
                      }`}
                    >
                      <img
                        src={
                          userProfilePic[
                            userType === "Mentee"
                              ? selectedConnection.mentor
                              : selectedConnection.mentee
                          ]
                        }
                        alt="Profile"
                        className="w-8 h-8 rounded-full mr-2"
                      />
                    </Link>
                    {userType === "Mentee"
                      ? userMap[selectedConnection.mentor]
                      : userMap[selectedConnection.mentee]}
                  </div>
                  <div className="flex-1 overflow-y-auto p-4">
                    {Object.keys(groupedMessages).map((date) => (
                      <div key={date}>
                        <div className="text-center my-4 text-gray-600">
                          {date}
                        </div>
                        {groupedMessages[date].map((msg) => (
                          <div
                            key={msg.id}
                            className={`mb-2 flex ${
                              msg.sender_id === userId
                                ? "justify-end"
                                : "justify-start"
                            }`}
                          >
                            <div
                              className={`flex flex-wrap item-center max-w-xs ${
                                msg.sender_id === userId
                                  ? "bg-green-100"
                                  : "bg-white"
                              } p-2 rounded-md shadow`}
                            >
                              <p className="text-gray-800">{msg.message}</p>
                              <span className="text-xs text-gray-500 pl-2 pt-1">
                                {new Date(msg.created_at).toLocaleTimeString(
                                  [],
                                  { hour: "2-digit", minute: "2-digit" }
                                )}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                    <div ref={messagesEndRef} />
                  </div>
                  {/* Send Message Form - Visible to Both User Types When Accepted */}
                  {selectedConnection.status === "Accepted" && (
                    <form
                      onSubmit={handleSendMessage}
                      className="flex p-4 border-t border-gray-300 bg-white my-4 rounded-lg"
                    >
                      <input
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Type your message..."
                        className="flex-grow p-2 rounded-l-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
                      />
                      <button
                        type="submit"
                        className="bg-primary text-white px-4 py-2 rounded-r-md hover:bg-primary-dark transition duration-300"
                      >
                        Send
                      </button>
                    </form>
                  )}
                  {/* Accept/Reject Buttons - Visible Only to Mentors When Waiting */}
                  {selectedConnection.status === "Waiting" && (
                    <div className="flex flex-col items-center justify-center h-full p-4">
                      <div className="my-4 text-center">
                        {selectedConnection.message_note}
                        <div className="text-sm mt-2 text-purple-600">
                          Click on the profile picture above to view the full
                          profile.
                        </div>
                      </div>
                      {userType === "Mentor" && (
                        <div className="flex justify-center mt-12">
                          <button
                            onClick={handleAccept}
                            className="bg-primary text-white px-4 py-2 rounded mr-2 hover:scale-105 transition duration-300"
                          >
                            Accept
                          </button>
                          <button
                            onClick={() => handleReject(selectedConnection.id)}
                            className="bg-red-300 bg-opacity-50 text-white px-4 py-2 rounded mr-2 hover:scale-105 transition duration-300"
                          >
                            Reject
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <div className="flex items-center justify-center h-full">
                  <p>Select a connection to start chatting.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className=" h-lvh flex items-center justify-center">
          <p>No connections found.</p>
        </div>
      )}
    </div>
  );
}

export default Chat;
