import React, { useEffect } from "react";
import supabase from "../../config/supabaseClient";
import swal from "sweetalert2";

function EditWebsiteModal({
  userId,
  websites,
  formData,
  setFormData,
  onClose,
  onSave,
}) {
  useEffect(() => {
    if (websites) {
      setFormData((prevData) => ({
        ...prevData,
        websites: {
          linkedin: websites.linkedin || "",
          research_url: websites.research_url || "",
          website_url: websites.website_url || "",
        },
      }));
    }
  }, [websites, setFormData]);

  const updateWebsite = async () => {
    const { error } = await supabase
      .from("user_website")
      .update({
        linkedin: formData.websites.linkedin,
        research_url: formData.websites.research_url,
        website_url: formData.websites.website_url,
      })
      .eq("user_id", userId);
    if (error) {
      console.error("Error updating website:", error);
    } else {
      swal.fire({
        title: "Success",
        text: "Website links updated successfully, refresh the page to see the changes",
        icon: "success",
      });
      onSave();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 pt-28 pb-8">
      <div className="bg-white p-4 rounded shadow-lg md:w-1/2 mx-4 overflow-y-auto h-[70vh]">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold text-primary mr-4">
            Edit Website Links
          </h2>
          <button
            onClick={onClose}
            className="bg-secondary-500 text-white px-4 py-2 rounded-md"
          >
            Close
          </button>
        </div>
        <div className="space-y-3 mt-4 md:mx-6">
          <div>
            <label className="block text-gray-700">LinkedIn URL</label>
            <input
              type="text"
              value={formData.websites.linkedin}
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  websites: {
                    ...prevData.websites,
                    linkedin: e.target.value,
                  },
                }));
              }}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">Research URL</label>
            <input
              type="text"
              value={formData.websites.research_url}
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  websites: {
                    ...prevData.websites,
                    research_url: e.target.value,
                  },
                }));
              }}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">Personal Website URL</label>
            <input
              type="text"
              value={formData.websites.website_url}
              onChange={(e) => {
                setFormData((prevData) => ({
                  ...prevData,
                  websites: {
                    ...prevData.websites,
                    website_url: e.target.value,
                  },
                }));
              }}
              className="w-full p-2 border rounded"
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button
            onClick={updateWebsite}
            className="bg-primary text-white px-4 py-2 my-4 rounded-md"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditWebsiteModal;
