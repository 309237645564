import React from "react";
import { Link } from "react-router-dom";
import makedifference from "../../assets/images/Beamentor/makedifference.svg";
import personalgrowth from "../../assets/images/Beamentor/personalgrowth.svg";
import Growthnetwork from "../../assets/images/Beamentor/Grownetwork.svg";
import Giveback from "../../assets/images/Beamentor/Giveback.svg";

function Beamentor() {
  return (
    <div className="pt-16  bg-zinc-100">
      {/* Header Section */}
      <section className="flex flex-col min-h-96 justify-center items-center my-auto">
        <div className="w-2/3 flex flex-col justify-center items-center space-y-4">
          <h1 className="text-3xl md:text-5xl font-bold mb-4 mx-auto text-center bg-gradient-to-r from-fuchsia-800 via-fuchsia-400 to-purple-900 bg-clip-text text-transparent">
            Become a Mentor: Shape Futures and Inspire Success
          </h1>

          <h2 className="md:mx-20 font-semibold  text-lg md:text-xl text-tertiary-500 text-center">
            Grow your skills and get rewarded for turning your expertise into
            impact.
          </h2>
          <Link to="/signup">
            <button className="my-4 bg-primary hover:scale-110 text-white transition duration-300 transform font-bold py-2 px-4 rounded">
              Begin your imapct
            </button>
          </Link>
        </div>
      </section>

      {/* Introduction */}
      <section className="min-h-40">
        <h3 className=" py-6 px-8 flex item-center bg-purple-900 bg-opacity-90 min-h-20  text-lg md:text-xl text-white justify-center font-bold mb-4 mx-auto text-center">
          <i>
            "Do you feel that your journey of applying for higher studies would
            have been smoother if you had the right mentor?"
          </i>
        </h3>
        <h2 className="md:text-lg text-tertiary-500 text-center my-6 md:my-10 mx-8 md:mx-80">
          Becoming a mentor means more than just giving advice—it’s about
          sharing your personal journey, offering genuine insights, and helping
          students make informed decisions about their future. At Uni-Bridge, we
          connect experienced professionals with students who aspire to follow
          similar paths. Whether it’s applying to global universities, exploring
          career options, or navigating academic challenges, your guidance can
          make a lasting impact.
        </h2>
      </section>

      {/* Why Mentor? */}
      <div className="py-10 px-4 md:px-32">
        <h2 className="text-3xl md:text-5xl font-bold text-center text-secondary-200 mb-10">
          Why Become a Mentor?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mx-8">
          <div className="flex flex-col min-h-40 items-center justify-cente bg-stone-200 hover:origin-top-left hover:rotate-1 transition duration-300 transform p-5 rounded-lg shadow">
            <img
              src={makedifference}
              alt="make a difference icon"
              className="w-20 h-20 pb-4"
            />
            <h3 className="pb-4 text-xl text-tertiary-600  font-semibold">
              Make a Difference
            </h3>
            <p className="text-center text-tertiary-600 ">
              Help students who are in the same position you once were and offer
              the guidance you wish you had.
            </p>
          </div>
          <div className="flex flex-col min-h-40 items-center justify-cente bg-stone-200 hover:scale-105 transition duration-300 transform p-5 rounded-lg shadow">
            <img
              src={personalgrowth}
              alt="make a difference icon"
              className="w-20 h-20 pb-4"
            />
            <h3 className="pb-4 text-xl text-tertiary-600  font-semibold">
              Professional Growth
            </h3>
            <p className="text-center text-tertiary-600 ">
              Enhance your leadership skills, enrich your resume, and gain
              valuable mentoring experience.
            </p>
          </div>
          <div className="flex flex-col min-h-40 items-center justify-cente bg-stone-200 hover:scale-105 transition duration-300 transform p-5 rounded-lg shadow">
            <img
              src={Growthnetwork}
              alt="make a difference icon"
              className="w-20 h-20 pb-4"
            />
            <h3 className="pb-4 text-xl text-tertiary-600  font-semibold">
              Expand Your Network
            </h3>
            <p className="text-center text-tertiary-600 ">
              Connect with a growing community of bright students, professionals
              and future leaders.
            </p>
          </div>
          <div className="flex flex-col min-h-40 items-center justify-cente bg-stone-200 hover:origin-top-right hover:rotate-1 transition duration-300 transform p-5 rounded-lg shadow">
            <img
              src={Giveback}
              alt="make a difference icon"
              className="w-20 h-20 pb-4"
            />
            <h3 className="pb-4 text-xl text-tertiary-600 font-semibold">
              Give Back
            </h3>
            <p className="text-center text-tertiary-600">
              Contribute to the success of the next generation by sharing your
              knowledge and expertise.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Beamentor;
