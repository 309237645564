import React from "react";
import { MdOutlineEdit, MdOutlineAddCircleOutline } from "react-icons/md";

function EducationSection({ education, type, onEdit, onAdd }) {
  return (
    <div className="">
      <h3 className="flex justify-between text-xl font-bold text-primary">
        Education
        <div className="flex space-x-4">
          <button onClick={onAdd} className=" hover:bg-gray-200">
            <MdOutlineAddCircleOutline className="w-6 h-6" />
          </button>
        </div>
      </h3>
      <ul>
        {education.map((edu, index) => (
          <li
            key={edu.id}
            className={`flex flex-col justify-start text-sm px-4 pb-2 rounded-lg ${
              index % 2 === 0 ? "bg-gray-100" : "bg-white"
            }`}
          >
            <div className="pt-4">
              <span className="font-semibold pr-2">
                {edu.degree} in {edu.field_of_study}{" "}
              </span>
            </div>
            <div>{edu.school}</div>
            <div className="">
              Funding: {edu.funding}{" "}
              <span className="ml-2 font-semibold">{edu.funding_type}</span>
            </div>
            {type === "Mentee" && edu.cgpa && <div>CGPA: {edu.cgpa}</div>}
            {console.log(edu.cgpa)}

            {edu.topic && (
              <div>
                <span className="font-semibold pr-2 ">Research Topic:</span>
                {edu.topic}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default EducationSection;
