import React from "react";
import Herosection from "../components/Herosection";

import Subherosection from "../components/Subherosection";
import Testimonials from "../components/Testimonials";
import FAQs from "./Resources/FAQs";
function Home() {
  return (
    <div>
      <Herosection />
      <div className="bg-secondary-900 flex h-20 px-2 justify-center items-center">
        <h2 className=" flex item-center text-center justify-around md:text-2xl text-white font-bold space-x-4">
          <h5 className="">Successful Mentors.</h5>
          <h5 className="">Real Experience.</h5>
          <h5 className="">Genuine Guidance.</h5>
        </h2>
      </div>
      <Subherosection />
      <Testimonials />

      <FAQs />
    </div>
  );
}

export default Home;
