export const Courses = [
  {
    subject_id: "1",
    subjects: "Science",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "2",
    subjects: "Natural Science",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "3",
    subjects: "Biology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "4",
    subjects: "Chemistry",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "5",
    subjects: "Physics",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "6",
    subjects: "Environmental Science",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "7",
    subjects: "Biochemistry",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "8",
    subjects: "Geology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "9",
    subjects: "Botany",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "10",
    subjects: "Zoology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "11",
    subjects: "Microbiology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "12",
    subjects: "Astronomy",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "13",
    subjects: "Marine Biology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "14",
    subjects: "Genetics",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "15",
    subjects: "Ecology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "16",
    subjects: "Neuroscience",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "17",
    subjects: "Bioinformatics",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "18",
    subjects: "Food Science",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "19",
    subjects: "Forensic Science",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "20",
    subjects: "Nutrition Science",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "21",
    subjects: "Molecular Biology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "22",
    subjects: "Plant Science",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "23",
    subjects: "Astrophysics",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "24",
    subjects: "Atmospheric Science",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "25",
    subjects: "Oceanography",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "26",
    subjects: "Environmental Biology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "27",
    subjects: "Environmental Chemistry",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "28",
    subjects: "Paleontology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "29",
    subjects: "Meteorology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "30",
    subjects: "Biophysics",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "31",
    subjects: "Environmental Toxicology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "32",
    subjects: "Environmental Management",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "33",
    subjects: "Environmental Policy",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "34",
    subjects: "Environmental Health",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "35",
    subjects: "Wildlife Biology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "36",
    subjects: "Conservation Biology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "37",
    subjects: "Evolutionary Biology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "38",
    subjects: "Immunology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "39",
    subjects: "Virology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "40",
    subjects: "Pharmacology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "41",
    subjects: "Analytical Chemistry",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "42",
    subjects: "Physical Chemistry",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "43",
    subjects: "Inorganic Chemistry",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "44",
    subjects: "Organic Chemistry",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "45",
    subjects: "Quantum Physics",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "46",
    subjects: "Nuclear Physics",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "47",
    subjects: "Theoretical Physics",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "48",
    subjects: "Applied Physics",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "49",
    subjects: "Earth Sciences",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "50",
    subjects: "Geophysics",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "51",
    subjects: "Environmental Geoscience",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "52",
    subjects: "Environmental Engineering Science",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "53",
    subjects: "Nanoscience",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "54",
    subjects: "Computational Biology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "55",
    subjects: "Systems Biology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "56",
    subjects: "Structural Biology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "57",
    subjects: "Cognitive Science",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "58",
    subjects: "Space Science",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "59",
    subjects: "Human Biology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "60",
    subjects: "Environmental Sustainability",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "61",
    subjects: "Agricultural Science",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "62",
    subjects: "Horticulture",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "63",
    subjects: "Soil Science",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "64",
    subjects: "Fisheries Science",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "65",
    subjects: "Entomology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "66",
    subjects: "Mycology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "67",
    subjects: "Toxicology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "68",
    subjects: "Bioregional Planning",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "69",
    subjects: "Earth and Planetary Sciences",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "70",
    subjects: "Remote Sensing and GIS",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "71",
    subjects: "Marine Chemistry",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "72",
    subjects: "Environmental Geology",
    category: "Science",
    discipline: "STEM",
  },
  {
    subject_id: "73",
    subjects: "Computer Science",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "74",
    subjects: "Data Science",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "75",
    subjects: "Artificial Intelligence",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "76",
    subjects: "Cybersecurity",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "77",
    subjects: "Software Engineering",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "78",
    subjects: "Information Technology",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "79",
    subjects: "Cloud Computing",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "80",
    subjects: "Blockchain",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "81",
    subjects: "Game Development",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "82",
    subjects: "Web Development",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "83",
    subjects: "Network Security",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "84",
    subjects: "Human-Computer Interaction (HCI)",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "85",
    subjects: "Internet of Things (IoT)",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "86",
    subjects: "Augmented Reality (AR)",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "87",
    subjects: "Virtual Reality (VR)",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "88",
    subjects: "Machine Learning",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "89",
    subjects: "Big Data Analytics",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "90",
    subjects: "Digital Media",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "91",
    subjects: "Mobile Application Development",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "92",
    subjects: "Information Systems",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "93",
    subjects: "Database Management",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "94",
    subjects: "Computer Networking",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "95",
    subjects: "Computer Forensics",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "96",
    subjects: "Multimedia Technology",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "97",
    subjects: "Bioinformatics",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "98",
    subjects: "Computational Science",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "99",
    subjects: "Software Development",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "100",
    subjects: "Software Testing",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "101",
    subjects: "Embedded Systems",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "102",
    subjects: "Automation and Control",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "103",
    subjects: "Robotics Technology",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "104",
    subjects: "Telecommunications",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "105",
    subjects: "Digital Forensics",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "106",
    subjects: "Systems Analysis",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "107",
    subjects: "IT Project Management",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "108",
    subjects: "Business Information Systems",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "109",
    subjects: "Health Informatics",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "110",
    subjects: "Geographical Information Systems (GIS)",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "111",
    subjects: "Technology Management",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "112",
    subjects: "Computer Graphics",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "113",
    subjects: "Cyber Law",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "114",
    subjects: "Ethical Hacking",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "115",
    subjects: "Quantum Computing",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "116",
    subjects: "Nanotechnology",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "117",
    subjects: "Digital Transformation",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "118",
    subjects: "Technology Entrepreneurship",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "119",
    subjects: "UX/UI Design",
    category: "Technology",
    discipline: "STEM",
  },
  {
    subject_id: "120",
    subjects: "Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "121",
    subjects: "Mechanical Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "122",
    subjects: "Electrical Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "123",
    subjects: "Civil Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "124",
    subjects: "Chemical Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "125",
    subjects: "Aerospace Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "126",
    subjects: "Industrial Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "127",
    subjects: "Automotive Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "128",
    subjects: "Robotics Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "129",
    subjects: "Biomedical Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "130",
    subjects: "Structural Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "131",
    subjects: "Environmental Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "132",
    subjects: "Materials Science and Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "133",
    subjects: "Petroleum Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "134",
    subjects: "Nuclear Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "135",
    subjects: "Systems Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "136",
    subjects: "Mechatronics Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "137",
    subjects: "Computer Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "138",
    subjects: "Telecommunications Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "139",
    subjects: "Marine Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "140",
    subjects: "Mining Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "141",
    subjects: "Agricultural Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "142",
    subjects: "Geological Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "143",
    subjects: "Textile Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "144",
    subjects: "Metallurgical Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "145",
    subjects: "Nanotechnology Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "146",
    subjects: "Energy Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "147",
    subjects: "Renewable Energy Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "148",
    subjects: "Biochemical Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "149",
    subjects: "Process Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "150",
    subjects: "Optical Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "151",
    subjects: "Safety Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "152",
    subjects: "Industrial Design Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "153",
    subjects: "Applied Mechanics",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "154",
    subjects: "Aeronautical Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "155",
    subjects: "Software Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "156",
    subjects: "Photonics Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "157",
    subjects: "Polymer Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "158",
    subjects: "Fire Protection Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "159",
    subjects: "Railway Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "160",
    subjects: "Offshore Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "161",
    subjects: "Sustainable Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "162",
    subjects: "Environmental Systems Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "163",
    subjects: "Urban Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "164",
    subjects: "Corrosion Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "165",
    subjects: "Audio Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "166",
    subjects: "Packaging Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "167",
    subjects: "Biomedical Systems Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "168",
    subjects: "Ocean Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "169",
    subjects: "Engineering Physics",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "170",
    subjects: "Agricultural and Biosystems Engineering",
    category: "Engineering",
    discipline: "STEM",
  },
  {
    subject_id: "171",
    subjects: "General Medicine",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "172",
    subjects: "Surgery",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "173",
    subjects: "Nursing",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "174",
    subjects: "Dentistry",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "175",
    subjects: "Pharmacy",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "176",
    subjects: "Veterinary Medicine",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "177",
    subjects: "Public Health",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "178",
    subjects: "Physiotherapy",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "179",
    subjects: "Radiology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "180",
    subjects: "Cardiology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "181",
    subjects: "Anesthesiology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "182",
    subjects: "Neurosurgery",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "183",
    subjects: "Obstetrics and Gynecology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "184",
    subjects: "Pathology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "185",
    subjects: "Medical Biotechnology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "186",
    subjects: "Dermatology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "187",
    subjects: "Orthopedics",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "188",
    subjects: "Medical Imaging",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "189",
    subjects: "Occupational Therapy",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "190",
    subjects: "Clinical Psychology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "191",
    subjects: "Oncology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "192",
    subjects: "Psychiatry",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "193",
    subjects: "Ophthalmology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "194",
    subjects: "Pediatrics",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "195",
    subjects: "Emergency Medicine",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "196",
    subjects: "Endocrinology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "197",
    subjects: "Gastroenterology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "198",
    subjects: "Hematology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "199",
    subjects: "Immunology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "200",
    subjects: "Nephrology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "201",
    subjects: "Neurology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "202",
    subjects: "Rheumatology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "203",
    subjects: "Urology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "204",
    subjects: "Audiology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "205",
    subjects: "Speech Therapy",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "206",
    subjects: "Midwifery",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "207",
    subjects: "Podiatry",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "208",
    subjects: "Epidemiology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "209",
    subjects: "Sports Medicine",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "210",
    subjects: "Forensic Medicine",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "211",
    subjects: "Clinical Research",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "212",
    subjects: "Genetic Counseling",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "213",
    subjects: "Health Administration",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "214",
    subjects: "Nutrition and Dietetics",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "215",
    subjects: "Medical Ethics",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "216",
    subjects: "Biomedical Science",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "217",
    subjects: "Tropical Medicine",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "218",
    subjects: "Occupational Health",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "219",
    subjects: "Paramedic Science",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "220",
    subjects: "Virology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "221",
    subjects: "Microbiology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "222",
    subjects: "Geriatric Medicine",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "223",
    subjects: "Nuclear Medicine",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "224",
    subjects: "Pharmacology",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "225",
    subjects: "Clinical Laboratory Science",
    category: "Medicine",
    discipline: "STEM",
  },
  {
    subject_id: "226",
    subjects: "Literature",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "227",
    subjects: "Philosophy",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "228",
    subjects: "History",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "229",
    subjects: "Linguistics",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "230",
    subjects: "Visual Arts",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "231",
    subjects: "Music",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "232",
    subjects: "Performing Arts",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "233",
    subjects: "Religious Studies",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "234",
    subjects: "Archaeology",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "235",
    subjects: "Film Studies",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "236",
    subjects: "Anthropology",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "237",
    subjects: "Creative Writing",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "238",
    subjects: "Modern Languages",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "239",
    subjects: "Art History",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "240",
    subjects: "Classical Studies",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "241",
    subjects: "Fashion Design",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "242",
    subjects: "Architecture",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "243",
    subjects: "Media Studies",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "244",
    subjects: "Graphic Design",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "245",
    subjects: "Interior Design",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "246",
    subjects: "Theater Arts",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "247",
    subjects: "Dance",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "248",
    subjects: "Cultural Studies",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "249",
    subjects: "Photography",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "250",
    subjects: "Digital Media Arts",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "251",
    subjects: "Animation",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "252",
    subjects: "Fine Arts",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "253",
    subjects: "Ceramics",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "254",
    subjects: "Textile Design",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "255",
    subjects: "Calligraphy",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "256",
    subjects: "Sculpture",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "257",
    subjects: "Jewelry Design",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "258",
    subjects: "Heritage Studies",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "259",
    subjects: "Museum Studies",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "260",
    subjects: "Ethics",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "261",
    subjects: "Aesthetics",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "262",
    subjects: "Asian Studies",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "263",
    subjects: "African Studies",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "264",
    subjects: "Middle Eastern Studies",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "265",
    subjects: "Latin American Studies",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "266",
    subjects: "Gender Studies",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "267",
    subjects: "American Studies",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "268",
    subjects: "Indigenous Studies",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "269",
    subjects: "Comparative Literature",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "270",
    subjects: "Translation Studies",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "271",
    subjects: "Music Technology",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "272",
    subjects: "Musicology",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "273",
    subjects: "Film Production",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "274",
    subjects: "Screenwriting",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "275",
    subjects: "Creative Arts Therapy",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "276",
    subjects: "Printmaking",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "277",
    subjects: "Sound Design",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "278",
    subjects: "Cultural Heritage Conservation",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "279",
    subjects: "Mythology",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "280",
    subjects: "Religious Education",
    category: "Arts and Humanities",
    discipline: "Arts and Humanities",
  },
  {
    subject_id: "281",
    subjects: "Accounting",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "282",
    subjects: "Business Administration",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "283",
    subjects: "Finance",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "284",
    subjects: "Economics",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "285",
    subjects: "International Business",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "286",
    subjects: "Marketing",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "287",
    subjects: "Human Resource Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "288",
    subjects: "Entrepreneurship",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "289",
    subjects: "Supply Chain Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "290",
    subjects: "Management Information Systems",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "291",
    subjects: "Actuarial Science",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "292",
    subjects: "Investment Banking",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "293",
    subjects: "Real Estate Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "294",
    subjects: "Risk Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "295",
    subjects: "Corporate Finance",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "296",
    subjects: "Business Analytics",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "297",
    subjects: "Hospitality Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "298",
    subjects: "Tourism Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "299",
    subjects: "Event Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "300",
    subjects: "Sports Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "301",
    subjects: "Retail Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "302",
    subjects: "E-Commerce",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "303",
    subjects: "Operations Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "304",
    subjects: "Project Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "305",
    subjects: "Strategic Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "306",
    subjects: "Public Relations",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "307",
    subjects: "Advertising",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "308",
    subjects: "Logistics Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "309",
    subjects: "Taxation",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "310",
    subjects: "Organizational Behavior",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "311",
    subjects: "International Trade",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "312",
    subjects: "Agribusiness",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "313",
    subjects: "Aviation Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "314",
    subjects: "Healthcare Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "315",
    subjects: "Innovation Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "316",
    subjects: "Quality Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "317",
    subjects: "Luxury Brand Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "318",
    subjects: "Management Consulting",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "319",
    subjects: "Financial Engineering",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "320",
    subjects: "Commodity Trading",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "321",
    subjects: "Industrial Relations",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "322",
    subjects: "Sustainable Business",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "323",
    subjects: "Technology Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "324",
    subjects: "Digital Marketing",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "325",
    subjects: "Corporate Governance",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "326",
    subjects: "Wealth Management",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "327",
    subjects: "International Finance",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "328",
    subjects: "Business Law",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "329",
    subjects: "Business Ethics",
    category: "Business and Finance",
    discipline: "Business & Management",
  },
  {
    subject_id: "330",
    subjects: "Political Science",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "331",
    subjects: "Sociology",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "332",
    subjects: "Psychology",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "333",
    subjects: "International Relations",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "334",
    subjects: "Economics",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "335",
    subjects: "Social Work",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "336",
    subjects: "Education",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "337",
    subjects: "Criminology",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "338",
    subjects: "Public Administration",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "339",
    subjects: "Gender Studies",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "340",
    subjects: "Urban Studies",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "341",
    subjects: "Development Studies",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "342",
    subjects: "Communication Studies",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "343",
    subjects: "Geography",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "344",
    subjects: "International Development",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "345",
    subjects: "Peace and Conflict Studies",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "346",
    subjects: "Anthropology",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "347",
    subjects: "Law",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "348",
    subjects: "Public Policy",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "349",
    subjects: "Environmental Policy",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "350",
    subjects: "Human Rights",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "351",
    subjects: "Demography",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "352",
    subjects: "Journalism",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "353",
    subjects: "Media and Communication",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "354",
    subjects: "Cultural Studies",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "355",
    subjects: "Archaeology",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "356",
    subjects: "Library and Information Science",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "357",
    subjects: "Rural Development",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "358",
    subjects: "Political Economy",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "359",
    subjects: "Behavioral Science",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "360",
    subjects: "Cognitive Science",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "361",
    subjects: "Environmental Studies",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "362",
    subjects: "Ethics and Social Philosophy",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "363",
    subjects: "Linguistics",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "364",
    subjects: "Migration Studies",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "365",
    subjects: "Social Psychology",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "366",
    subjects: "Sociology of Education",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "367",
    subjects: "Comparative Politics",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "368",
    subjects: "Organizational Studies",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "369",
    subjects: "Regional Studies",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "370",
    subjects: "Health Policy",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "371",
    subjects: "Gerontology",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "372",
    subjects: "Community Development",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "373",
    subjects: "Stratification and Inequality Studies",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "374",
    subjects: "Disability Studies",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "375",
    subjects: "Family Studies",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "376",
    subjects: "Labor Studies",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "377",
    subjects: "Ethnic Studies",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "378",
    subjects: "Peace Studies",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "379",
    subjects: "Strategic Studies",
    category: "Social Sciences",
    discipline: "Social Sciences",
  },
  {
    subject_id: "380",
    subjects: "Bachelor of Laws (LLB)",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "381",
    subjects: "Master of Laws (LLM)",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "382",
    subjects: "International Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "383",
    subjects: "Corporate Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "384",
    subjects: "Criminal Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "385",
    subjects: "Environmental Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "386",
    subjects: "Intellectual Property Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "387",
    subjects: "Human Rights Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "388",
    subjects: "Tax Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "389",
    subjects: "Commercial Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "390",
    subjects: "Health Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "391",
    subjects: "Maritime Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "392",
    subjects: "Sports Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "393",
    subjects: "Technology Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "394",
    subjects: "Constitutional Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "395",
    subjects: "Labor Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "396",
    subjects: "Family Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "397",
    subjects: "Media Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "398",
    subjects: "Cyber Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "399",
    subjects: "Administrative Law",
    category: "Law",
    discipline: "Law",
  },
  {
    subject_id: "400",
    subjects: "Early Childhood Education",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "401",
    subjects: "Primary Education",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "402",
    subjects: "Secondary Education",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "403",
    subjects: "Special Education",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "404",
    subjects: "Educational Psychology",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "405",
    subjects: "Educational Leadership",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "406",
    subjects: "Curriculum and Instruction",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "407",
    subjects: "TESOL (Teaching English to Speakers of Other Languages)",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "408",
    subjects: "Adult Education",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "409",
    subjects: "Educational Technology",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "410",
    subjects: "Higher Education Administration",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "411",
    subjects: "Educational Policy",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "412",
    subjects: "Counseling and Guidance",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "413",
    subjects: "Distance Education",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "414",
    subjects: "STEM Education",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "415",
    subjects: "Art Education",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "416",
    subjects: "Physical Education",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "417",
    subjects: "Language Education",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "418",
    subjects: "Literacy Education",
    category: "Education",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "419",
    subjects: "Agronomy",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "420",
    subjects: "Horticulture",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "421",
    subjects: "Animal Science",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "422",
    subjects: "Forestry",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "423",
    subjects: "Soil Science",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "424",
    subjects: "Agricultural Economics",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "425",
    subjects: "Food Science and Technology",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "426",
    subjects: "Agricultural Engineering",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "427",
    subjects: "Plant Pathology",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "428",
    subjects: "Agricultural Biotechnology",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "429",
    subjects: "Aquaculture",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "430",
    subjects: "Agricultural Business Management",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "431",
    subjects: "Crop Science",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "432",
    subjects: "Dairy Science",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "433",
    subjects: "Poultry Science",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "434",
    subjects: "Viticulture and Enology",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "435",
    subjects: "Sustainable Agriculture",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "436",
    subjects: "Agricultural Extension",
    category: "Agriculture",
    discipline: "STEM",
  },
  {
    subject_id: "437",
    subjects: "Environmental Studies",
    category: "Environmental Studies",
    discipline: "STEM",
  },
  {
    subject_id: "438",
    subjects: "Environmental Management",
    category: "Environmental Studies",
    discipline: "STEM",
  },
  {
    subject_id: "439",
    subjects: "Environmental Policy",
    category: "Environmental Studies",
    discipline: "STEM",
  },
  {
    subject_id: "440",
    subjects: "Conservation Biology",
    category: "Environmental Studies",
    discipline: "STEM",
  },
  {
    subject_id: "441",
    subjects: "Sustainable Development",
    category: "Environmental Studies",
    discipline: "STEM",
  },
  {
    subject_id: "442",
    subjects: "Renewable Energy",
    category: "Environmental Studies",
    discipline: "STEM",
  },
  {
    subject_id: "443",
    subjects: "Climate Science",
    category: "Environmental Studies",
    discipline: "STEM",
  },
  {
    subject_id: "444",
    subjects: "Environmental Planning",
    category: "Environmental Studies",
    discipline: "STEM",
  },
  {
    subject_id: "445",
    subjects: "Natural Resource Management",
    category: "Environmental Studies",
    discipline: "STEM",
  },
  {
    subject_id: "446",
    subjects: "Wildlife Management",
    category: "Environmental Studies",
    discipline: "STEM",
  },
  {
    subject_id: "447",
    subjects: "Environmental Economics",
    category: "Environmental Studies",
    discipline: "STEM",
  },
  {
    subject_id: "448",
    subjects: "Urban Environmental Management",
    category: "Environmental Studies",
    discipline: "STEM",
  },
  {
    subject_id: "449",
    subjects: "Environmental Education",
    category: "Environmental Studies",
    discipline: "STEM",
  },
  {
    subject_id: "450",
    subjects: "Environmental Law",
    category: "Environmental Studies",
    discipline: "STEM",
  },
  {
    subject_id: "451",
    subjects: "Environmental Engineering",
    category: "Environmental Studies",
    discipline: "STEM",
  },
  {
    subject_id: "452",
    subjects: "Environmental Toxicology",
    category: "Environmental Studies",
    discipline: "STEM",
  },
  {
    subject_id: "453",
    subjects: "Environmental Health",
    category: "Environmental Studies",
    discipline: "STEM",
  },
  {
    subject_id: "454",
    subjects: "Hospitality Management",
    category: "Hospitality and Tourism",
    discipline: "Business & Management",
  },
  {
    subject_id: "455",
    subjects: "Tourism Management",
    category: "Hospitality and Tourism",
    discipline: "Business & Management",
  },
  {
    subject_id: "456",
    subjects: "Hotel Management",
    category: "Hospitality and Tourism",
    discipline: "Business & Management",
  },
  {
    subject_id: "457",
    subjects: "Culinary Arts",
    category: "Hospitality and Tourism",
    discipline: "Business & Management",
  },
  {
    subject_id: "458",
    subjects: "Event Management",
    category: "Hospitality and Tourism",
    discipline: "Business & Management",
  },
  {
    subject_id: "459",
    subjects: "Travel and Tourism",
    category: "Hospitality and Tourism",
    discipline: "Business & Management",
  },
  {
    subject_id: "460",
    subjects: "Resort Management",
    category: "Hospitality and Tourism",
    discipline: "Business & Management",
  },
  {
    subject_id: "461",
    subjects: "Restaurant Management",
    category: "Hospitality and Tourism",
    discipline: "Business & Management",
  },
  {
    subject_id: "462",
    subjects: "Hospitality and Tourism Marketing",
    category: "Hospitality and Tourism",
    discipline: "Business & Management",
  },
  {
    subject_id: "463",
    subjects: "Casino Management",
    category: "Hospitality and Tourism",
    discipline: "Business & Management",
  },
  {
    subject_id: "464",
    subjects: "Beverage Management",
    category: "Hospitality and Tourism",
    discipline: "Business & Management",
  },
  {
    subject_id: "465",
    subjects: "Spa and Wellness Management",
    category: "Hospitality and Tourism",
    discipline: "Business & Management",
  },
  {
    subject_id: "466",
    subjects: "Sustainable Tourism",
    category: "Hospitality and Tourism",
    discipline: "Business & Management",
  },
  {
    subject_id: "467",
    subjects: "Hospitality Entrepreneurship",
    category: "Hospitality and Tourism",
    discipline: "Business & Management",
  },
  {
    subject_id: "468",
    subjects: "Graphic Design",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "469",
    subjects: "Interior Design",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "470",
    subjects: "Fashion Design",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "471",
    subjects: "Industrial Design",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "472",
    subjects: "Product Design",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "473",
    subjects: "Web Design",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "474",
    subjects: "UX/UI Design",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "475",
    subjects: "Visual Communication Design",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "476",
    subjects: "Textile Design",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "477",
    subjects: "Jewelry Design",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "478",
    subjects: "Animation and Visual Effects",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "479",
    subjects: "Game Design",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "480",
    subjects: "Interaction Design",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "481",
    subjects: "Exhibition Design",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "482",
    subjects: "Transportation Design",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "483",
    subjects: "Design Management",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "484",
    subjects: "Design Thinking",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "485",
    subjects: "Digital Design",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "486",
    subjects: "Sustainable Design",
    category: "Design",
    discipline: "STEM",
  },
  {
    subject_id: "487",
    subjects: "Music Performance",
    category: "Performing Arts",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "488",
    subjects: "Dance",
    category: "Performing Arts",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "489",
    subjects: "Theater Arts",
    category: "Performing Arts",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "490",
    subjects: "Acting",
    category: "Performing Arts",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "491",
    subjects: "Music Composition",
    category: "Performing Arts",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "492",
    subjects: "Musical Theater",
    category: "Performing Arts",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "493",
    subjects: "Stage Management",
    category: "Performing Arts",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "494",
    subjects: "Sound Design",
    category: "Performing Arts",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "495",
    subjects: "Film Production",
    category: "Performing Arts",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "496",
    subjects: "Screenwriting",
    category: "Performing Arts",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "497",
    subjects: "Cinematography",
    category: "Performing Arts",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "498",
    subjects: "Directing",
    category: "Performing Arts",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "499",
    subjects: "Playwriting",
    category: "Performing Arts",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "500",
    subjects: "Opera Studies",
    category: "Performing Arts",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "501",
    subjects: "Lighting Design",
    category: "Performing Arts",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "502",
    subjects: "Production Design",
    category: "Performing Arts",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "504",
    subjects: "French Studies",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "505",
    subjects: "German Studies",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "506",
    subjects: "Spanish Studies",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "507",
    subjects: "Chinese Studies",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "508",
    subjects: "Japanese Studies",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "509",
    subjects: "Arabic Studies",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "510",
    subjects: "Russian Studies",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "511",
    subjects: "Latin American Studies",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "512",
    subjects: "African Studies",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "513",
    subjects: "Asian Studies",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "514",
    subjects: "European Studies",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "515",
    subjects: "Indigenous Studies",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "516",
    subjects: "Cultural Anthropology",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "517",
    subjects: "Comparative Cultural Studies",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "518",
    subjects: "Translation and Interpretation",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "519",
    subjects: "Applied Linguistics",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "520",
    subjects: "Scandinavian Studies",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "521",
    subjects: "Middle Eastern Studies",
    category: "Language and Cultural Studies",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "522",
    subjects: "Sports Science",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "523",
    subjects: "Kinesiology",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "524",
    subjects: "Culinary Arts",
    category: "Miscellaneous",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "525",
    subjects: "Aviation",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "526",
    subjects: "Military Science",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "527",
    subjects: "Paralegal Studies",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "528",
    subjects: "Gerontology",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "529",
    subjects: "Theology",
    category: "Miscellaneous",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "530",
    subjects: "Funeral Service Education",
    category: "Miscellaneous",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "531",
    subjects: "Occupational Safety and Health",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "532",
    subjects: "Marine Sciences",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "533",
    subjects: "Library Science",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "534",
    subjects: "Quality Assurance",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "535",
    subjects: "Records Management",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "536",
    subjects: "Security Studies",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "537",
    subjects: "Sustainable Agriculture",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "538",
    subjects: "Health Informatics",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "539",
    subjects: "Data Analytics",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "540",
    subjects: "Biotechnology",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "541",
    subjects: "Maritime Studies",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "542",
    subjects: "Fashion Merchandising",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "543",
    subjects: "Advertising and Public Relations",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "544",
    subjects: "Biomedical Sciences",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "545",
    subjects: "Behavioral Sciences",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "546",
    subjects: "Entrepreneurship and Innovation",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "547",
    subjects: "Family and Consumer Sciences",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "548",
    subjects: "Global Studies",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "549",
    subjects: "Human Services",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "550",
    subjects: "Occupational Therapy",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "551",
    subjects: "Optometry",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "552",
    subjects: "Speech-Language Pathology",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "553",
    subjects: "Urban Planning",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "554",
    subjects: "Human Ecology",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "555",
    subjects: "Culinary Nutrition",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "556",
    subjects: "Outdoor Education",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "557",
    subjects: "Equine Studies",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "558",
    subjects: "Horology",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "559",
    subjects: "Art Therapy",
    category: "Miscellaneous",
    discipline: "Art & Humanities",
  },
  {
    subject_id: "560",
    subjects: "Disaster Management",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "561",
    subjects: "Fashion Marketing",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "562",
    subjects: "Heritage Management",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "563",
    subjects: "Paleontology",
    category: "Miscellaneous",
    discipline: "STEM",
  },
  {
    subject_id: "564",
    subjects: "Astrobiology",
    category: "Miscellaneous",
    discipline: "STEM",
  },
];
