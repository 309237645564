import React, { useState } from "react";
import supabase from "../../config/supabaseClient";
import default_profile from "../../assets/images/default_profile.svg";
import { Link } from "react-router-dom";
import Win from "../../assets/images/Beamentor/Win.svg";
import Swal from "sweetalert2";

function Mentors() {
  const [userEducation, setUserEducation] = useState([]);
  const [mentor, setMentor] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [userFilter, setUserFilter] = useState({
    degree: "Undergraduate",
    discipline: "STEM",
    region: "UK",
  });
  const [displayCount, setDisplayCount] = useState(9);
  const [filteredUsers, setFilteredUsers] = useState([]); // Change to state variable

  const degrees = [
    { id: 1, name: "Undergraduate" },
    { id: 2, name: "Master" },
    { id: 3, name: "PhD" },
    { id: 4, name: "Postdoctoral" },
    { id: 5, name: "Professional career" },
  ];

  const disciplines = [
    { id: 1, name: "STEM" },
    { id: 2, name: "Art & Humanities" },
    { id: 3, name: "Business & Management" },
    { id: 4, name: "Social sciences" },
    { id: 5, name: "Law" },
  ];

  const regions = [
    { id: 1, name: "UK" },
    { id: 2, name: "US" },
    { id: 3, name: "Canada" },
    { id: 4, name: "Europe" },
    { id: 5, name: "Australia & NZ" },
    { id: 6, name: "Japan" },
    { id: 7, name: "South Korea" },
    { id: 8, name: "Singapore" },
  ];

  const fetchMentors = async () => {
    const { data, error } = await supabase.rpc("get_mentors_by_preferences", {
      p_degree: userFilter.degree,
      p_discipline: userFilter.discipline,
      p_region: userFilter.region,
    });

    if (error) {
      console.error(error);
    } else {
      const userIds = data.map((mentor) => mentor.user_id);
      const { data: mentorsData, error: mentorsError } = await supabase
        .from("users")
        .select("user_id, name, profile_pic")
        .in("user_id", userIds);

      if (mentorsError) {
        console.error(mentorsError);
      } else {
        setFilteredUsers(mentorsData); // Update state instead of local variable
      }

      const { data: educationData, error: educationError } = await supabase
        .from("user_education")
        .select("*")
        .in("user_id", userIds);
      if (educationError) console.error(educationError);
      else setUserEducation(educationData);
    }
  };

  const handleFilterChange = (e) => {
    setUserFilter({
      ...userFilter,
      [e.target.name]: e.target.value,
    });
  };

  const handleApplyFilters = () => {
    fetchMentors();
  };

  const handleConnectClick = (user_id) => {
    setIsDialogOpen(true);
    setMentor(user_id);
  };

  const handleSendMessage = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();

    const { data: Types, error: TypesError } = await supabase
      .from("users")
      .select("type")
      .eq("user_id", user.id);

    if (TypesError) {
      console.error("Error fetching types:", TypesError);
    } else {
      if (Types.length > 0 && Types[0].type === "Mentor") {
        Swal.fire({
          title:
            "You are a mentor, sorry you cannot connect to a mentor at the moment",
          icon: "error",
        });
        return;
      }
    }

    const { data: connection, error: Connectionerror } = await supabase
      .from("connection")
      .select("status, mentor")
      .eq("mentee", user.id);

    if (Connectionerror) {
      console.error("Error inserting data:", Connectionerror);
    } else {
      if (
        connection &&
        connection.filter((conn) => conn.status === "Accepted").length > 0
      ) {
        Swal.fire({
          title:
            "Sorry, there is a huge demand for a mentor and at this time, we can only connect you with one mentor at a time.",
          icon: "info",
        });
        return;
      }

      if (
        connection &&
        connection.filter((conn) => conn.mentor === mentor).length > 0
      ) {
        Swal.fire({
          title: "You have tried to connect this mentor before.",
          icon: "error",
        });
        return;
      }

      if (connection.filter((conn) => conn.status === "Waiting").length < 3) {
        const { data, error } = await supabase
          .from("connection")
          .insert([
            {
              mentee: user.id,
              mentor: mentor,
              message_note: message,
            },
          ])
          .select();

        if (error) {
          console.error("Error inserting data:", error);
        } else {
          console.log("Data inserted successfully", data);
        }
        Swal.fire({
          title: "Message sent",
          text: message,
          icon: "success",
        });

        setIsDialogOpen(false);
        setMessage("");
      } else {
        Swal.fire({
          title:
            "Sorry, you can only send invite to 3 mentors. Please wait for the response",
          icon: "error",
        });
      }
    }
  };

  return (
    <div className="min-h-lvh pt-16">
      <div className="min-h-96 bg-stone-100 text-secondary-100 text-3xl md:text-5xl font-bold flex flex-col md:flex-row text-center items-center justify-center h-72">
        <p className="py-10 md:py-0">
          We Guide,
          <br /> You Achieve,
          <br />
          Together We Conquer
        </p>
        <img src={Win} alt="Win" className="md:w-1/2 px-16 md:p-24" />
      </div>

      <div>
        <div className="text-secondary-200 text-xl md:text-3xl font-bold flex justify-center my-4 md:my-10 mx-auto text-center">
          Let's help you find the perfect mentor!
        </div>
        <div className="text-secondary-200 text-xl md:text-2xl text-semibold flex justify-center my-4 md:my-10">
          What do you aspire?
        </div>
        <div className="flex flex-col md:flex-row justify-evenly md:justify-center md:items-center mx-8 my-4">
          <div className="flex md:flex-row justify-between text-center items-center my-4 mx-4">
            <label className="flex text-md md:text-lg font-semibold text-primary mb-2 md:mb-0 md:mr-4">
              Degree
            </label>
            <select
              className="p-2 min-w-52 border rounded-md shadow-md text-sm"
              onChange={handleFilterChange}
              name="degree"
            >
              {degrees.map((degree) => (
                <option key={degree.id} value={degree.name}>
                  {degree.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex md:flex-row justify-between items-center mb-4 md:mb-0 mx-4">
            <label className="text-md md:text-lg font-semibold text-primary mb-2 md:mb-0 md:mr-4 md:ml-4">
              Discipline
            </label>
            <select
              className="p-2 min-w-52 border rounded-md shadow-md text-sm"
              onChange={handleFilterChange}
              name="discipline"
            >
              {disciplines.map((discipline) => (
                <option key={discipline.id} value={discipline.name}>
                  {discipline.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex md:flex-row justify-between items-center mb-4 md:mb-0 mx-4">
            <label className="text-md md:text-lg font-semibold text-primary mb-2 md:mb-0 md:mr-4 md:ml-4">
              Region
            </label>
            <select
              className="p-2 min-w-52 border rounded-md shadow-md text-sm"
              onChange={handleFilterChange}
              name="region"
            >
              {regions.map((region) => (
                <option key={region.id} value={region.name}>
                  {region.name}
                </option>
              ))}
            </select>
          </div>
          <button
            className=" p-2 bg-primary text-white rounded-md hover:scale-105"
            onClick={handleApplyFilters}
          >
            Apply
          </button>
        </div>
        <div>
          <div className="my-10 mx-6 md:mx-20">
            <div className="flex flex-wrap justify-center gap-4">
              {filteredUsers.length === 0 ? (
                <div className="my-2 px-4 min-w-96 min-h-72 text-center">
                  <div className="border rounded-xl p-4 shadow-lg">
                    <h3 className="text-center font-semibold mb-4">
                      All mentors are busy
                    </h3>
                  </div>
                </div>
              ) : (
                filteredUsers.slice(0, displayCount).map((user) => (
                  <div
                    key={user.user_id}
                    className="my-2 px-4 min-w-96 min-h-72"
                  >
                    <div className="border rounded-xl p-6 shadow-lg">
                      <img
                        src={user.profile_pic || default_profile}
                        alt={user.name}
                        className="object-cover rounded-full h-52 w-52 mx-auto border-2"
                      />
                      <hr className="border-t border-gray-300 my-4" />
                      <h3 className="text-center font-semibold mb-4">
                        {user.name}
                      </h3>

                      <ul className="text-base px-2 h-24 md:px-8">
                        {userEducation
                          .filter(
                            (education) => education.user_id === user.user_id
                          )
                          .slice(0, 1)
                          .map((education) => (
                            <li
                              key={education.id}
                              className="flex flex-col justify-start"
                            >
                              <div>
                                <span className="font-semibold pr-2">
                                  {education.degree}:
                                </span>
                                {education.school}
                              </div>
                              <div>
                                <span className="font-semibold pr-2">
                                  Subject:
                                </span>
                                {education.field_of_study}
                              </div>

                              {education.funding && (
                                <div className="mt-2 text-sm flex flex-wrap items-center">
                                  <span className="bg-green-500 text-white rounded-xl px-2 py-1 pr-2">
                                    {`${education.funding}-Funding`}
                                  </span>
                                  <span className=" font-semibold ml-2 ">
                                    {education.funding_name}
                                  </span>
                                </div>
                              )}
                            </li>
                          ))}
                      </ul>
                      <div className="flex justify-evenly">
                        <Link
                          to={`/profile/${user.user_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center justify-center"
                        >
                          <button className="mt-4 bg-purple-500 bg-opacity-90 text-white text-base font-semibold py-2 px-4 rounded">
                            Learn more
                          </button>
                        </Link>

                        <button
                          className="mt-4 bg-primary text-white text-base font-semibold py-2 px-4 rounded"
                          onClick={() => handleConnectClick(user.user_id)}
                        >
                          connect
                        </button>
                      </div>
                      {isDialogOpen && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40">
                          <div className="bg-white p-4 rounded shadow-lg w-1/2">
                            <h3 className="text-lg font-semibold">
                              Add a note (optional)
                            </h3>
                            <p className="text-sm">
                              <i>
                                Reminder: you can send messages to only 3 mentor
                                at a time
                              </i>
                            </p>
                            <textarea
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              placeholder="This can increase your chances of getting a response. For example, I aspire to do a PhD in Physics at Cambridge University and I also have master's in Physics from IIT."
                              className="w-full border rounded p-2"
                              rows="4"
                            />
                            <div className="flex justify-end mt-2">
                              <button
                                className="bg-primary text-white py-1 px-3 rounded mr-2"
                                onClick={handleSendMessage}
                              >
                                Send
                              </button>
                              <button
                                className="bg-gray-300 text-black py-1 px-3 rounded"
                                onClick={() => setIsDialogOpen(false)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
            {filteredUsers.length > displayCount && (
              <button
                className="mt-4 bg-primary text-white py-2 px-4 rounded"
                onClick={() => setDisplayCount(displayCount + 9)}
              >
                Load more
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mentors;
