import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import supabase from "../../config/supabaseClient";
import default_profile from "../../assets/images/default_profile.svg";
// import Arjun_pic from "../../assets/images/ForAbout/Arjun_pic_crop.png";
import Linkedin from "../../assets/images/Linkedin.svg";
import Website_personal from "../../assets/images/Website_personal.svg";
import Google_Scholar from "../../assets/images/Google_Scholar.svg";

function MentorDetails() {
  const { userId } = useParams(); // Get the userId from the URL
  const [mentor, setMentor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMentorDetails = async () => {
      try {
        const { data: mentorData, error: mentorError } = await supabase
          .from("users") // Fetch mentor details
          .select("*")
          .eq("user_id", userId)
          .single();

        if (mentorError) throw mentorError;

        const { data: educationData, error: educationError } = await supabase
          .from("user_education") // Fetch user education
          .select("*")
          .eq("user_id", userId);

        if (educationError) throw educationError;

        const { data: experienceData, error: experienceError } = await supabase
          .from("user_experience") // Fetch user experience
          .select("*")
          .eq("user_id", userId);

        if (experienceError) throw experienceError;

        const { data: disciplinesData, error: disciplinesError } =
          await supabase
            .from("user_disciplines") // Fetch user disciplines
            .select("*")
            .eq("user_id", userId);

        if (disciplinesError) throw disciplinesError;

        const { data: websiteData, error: websiteError } = await supabase
          .from("user_website") // Fetch user website
          .select("*")
          .eq("user_id", userId);

        if (websiteError) throw websiteError;

        setMentor({
          ...mentorData,
          education: educationData,
          experience: experienceData,
          disciplines: disciplinesData,
          website: websiteData || [], // Ensure website data is an array
        }); // Combine all data
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMentorDetails();
  }, [userId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="pt-16 min-h-lvh">
      <div className="mx-6 md:mx-20">
        <h1 className="text-xl md:text-3xl font-bold text-center text-primary my-6">
          Mentor Details
        </h1>
        {mentor ? (
          <div className="flex flex-col ">
            <div className="mx-10 flex flex-col md:flex-row">
              <div className="flex flex-col md:w-1/2 md:max-md">
                <img
                  src={mentor.profile_pic || default_profile} // Use a default image if none
                  alt={mentor.name}
                  className="object-cover rounded-full h-52 w-52 mx-auto border-2"
                />
                <h2 className="text-center font-bold text-primary mt-4">
                  {mentor.name}
                </h2>
                {mentor.experience.slice(0, 1).map((exp) => (
                  <p
                    key={exp.id}
                    className="text-center font-bold text-primary mb-4"
                  >
                    {exp.position} {exp.company}
                  </p>
                ))}
              </div>

              <div className="md:mx-6">
                <h3 className="text-xl font-bold text-primary ">Education</h3>
                {mentor.education.map((edu) => (
                  <li
                    key={edu.id}
                    className="flex flex-col justify-start text-sm"
                  >
                    <div className="pt-4">
                      <span className="font-semibold pr-2">
                        {edu.degree} in {edu.field_of_study}{" "}
                      </span>
                    </div>
                    <div>{edu.school}</div>
                    {edu.funding && (
                      <div className="italic">
                        {edu.funding}-Funding: {edu.funding_name}
                      </div>
                    )}
                    {edu.topic && (
                      <div>
                        <span className="font-semibold pr-2 ">
                          Research Topic:
                        </span>
                        {edu.topic}
                      </div>
                    )}
                  </li>
                ))}

                <h3 className="text-xl font-bold text-primary mt-4">
                  Experience
                </h3>
                {mentor.experience.map((exp) => (
                  <div key={exp.id} className="text-sm">
                    {exp.job_title} at {exp.company}
                  </div>
                ))}
                <div className="text-sm flex items-center mt-6">
                  <div className="flex items-start">
                    {mentor.website.length > 0 &&
                      mentor.website[0].linkedin && (
                        <a
                          href={mentor.website[0].linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center mb-2"
                        >
                          <img
                            src={Linkedin}
                            alt="LinkedIn"
                            className="w-6 h-6 mr-3"
                          />
                        </a>
                      )}
                    {mentor.website.length > 0 &&
                      mentor.website[0].research_url && (
                        <a
                          href={mentor.website[0].research_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center mb-2"
                        >
                          <img
                            src={Google_Scholar}
                            alt="Google Scholar"
                            className="w-6 h-6 mr-3"
                          />
                        </a>
                      )}
                    {mentor.website.length > 0 &&
                      mentor.website[0].website_url && (
                        <a
                          href={mentor.website[0].website_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center"
                        >
                          <img
                            src={Website_personal}
                            alt="Personal Website"
                            className="w-6 h-6 mr-3"
                          />
                        </a>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="my-10 mx-10">
              <div className="bg-stone-100 p-4 rounded-xl min-h-48">
                <strong>ABOUT ME:</strong>
                <div>{mentor.bio}</div>
              </div>
            </div>
          </div>
        ) : (
          <p>No mentor found.</p>
        )}
      </div>
    </div>
  );
}

export default MentorDetails;
