import React from "react";
import { countries } from "../../data/Countries";
import { Courses } from "../../data/Courses";
import swal from "sweetalert2";
import supabase from "../../config/supabaseClient";

function EditExperienceModal({
  userId,
  formData,
  setFormData,
  onClose,
  onSave,
}) {
  const updateExperience = async () => {
    const { error } = await supabase.from("user_experience").insert({
      user_id: userId,
      job_title: formData.experience.jobTitle,
      company: formData.experience.company,
      country: formData.experience.country,
      area_of_work: formData.experience.areaOfWork,
      current_position: formData.experience.currentPosition,
      work_period: formData.experience.workperiod,
    });
    if (error) {
      console.error("Error updating experience:", error);
    } else {
      swal.fire({
        title: "Success",
        text: "Experience updated successfully, refresh the page to see the changes",
        icon: "success",
      });
      onSave();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 pt-28 pb-8">
      <div className="bg-white p-4 rounded shadow-lg md:w-1/2 mx-4 overflow-y-auto h-[70vh]">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold text-primary">Edit Experience</h2>
          <button
            onClick={onClose}
            className="bg-secondary-500 text-white px-4 py-2 rounded-md"
          >
            Close
          </button>
        </div>

        <div className="space-y-3 mt-4 md:mx-6">
          <div>
            <label className="block text-gray-700">Job Title</label>
            <input
              type="text"
              name="jobTitle"
              value={formData.experience.jobTitle}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  experience: {
                    ...formData.experience,
                    jobTitle: e.target.value,
                  },
                })
              }
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">Company</label>
            <input
              type="text"
              name="company"
              value={formData.experience.company}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  experience: {
                    ...formData.experience,
                    company: e.target.value,
                  },
                })
              }
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">Country</label>
            <input
              type="text"
              name="country"
              value={formData.experience.country}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  experience: {
                    ...formData.experience,
                    country: e.target.value,
                  },
                })
              }
              className="w-full p-2 border rounded"
              list="country-list"
            />
            <datalist id="country-list">
              {countries.map((country) => (
                <option key={country.code} value={country.name}>
                  {country.name}
                </option>
              ))}
            </datalist>
          </div>

          <div>
            <label className="block text-gray-700">Area of Work</label>
            <input
              type="text"
              name="areaOfWork"
              value={formData.experience.areaOfWork}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  experience: {
                    ...formData.experience,
                    areaOfWork: e.target.value,
                  },
                })
              }
              className="w-full p-2 border rounded"
              list="courses-list"
            />
            <datalist id="courses-list">
              {Courses.map((course) => (
                <option key={course.subject_id} value={course.subjects}>
                  {course.subjects}
                </option>
              ))}
            </datalist>
          </div>

          <div>
            <label className="flex text-gray-700 pt-2 items-center">
              I am working here
              <input
                type="checkbox"
                name="currentPosition"
                checked={formData.experience.currentPosition}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    experience: {
                      ...formData.experience,
                      currentPosition: e.target.checked,
                    },
                  })
                }
                className="inline-flex ml-2 w-6 h-8"
              />
            </label>
          </div>

          <div>
            <label className="block text-gray-700">
              How long have you worked here? (in months)
              <input
                type="number"
                name="workperiod"
                value={formData.experience.workperiod || 0}
                className="w-full p-2 border rounded"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    experience: {
                      ...formData.experience,
                      workperiod: e.target.value,
                    },
                  })
                }
              />
            </label>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            onClick={updateExperience}
            className="bg-primary text-white px-4 py-2 my-4 rounded-md"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditExperienceModal;
