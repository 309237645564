import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";

function Footer() {
  const navigate = useNavigate();

  return (
    <footer class=" w-full bg-primary text-white p-5">
      <div class="max-w-7xl mx-auto flex flex-wrap justify-between items-center">
        <div>
          <h2
            class="text-3xl font-bold cursor-pointer"
            onClick={() => navigate("/")}
          >
            Unibridge
          </h2>
          <h5 class="text-lg">Bridging futures, one step at a time</h5>
          <div class="flex space-x-4 my-3 cursor-pointer ">
            <FaInstagram className="text-purple-500  text-2xl" />
            <FaFacebookF className="text-blue-400 text-2xl" />
            <FaLinkedinIn className="text-blue-500 text-2xl" />
            {/* <a href="#" aria-label="Instagram">
              <FaInstagram />
            
            </a>
            <a href="#" aria-label="Facebook">
              <FaFacebookF />
             
            </a>
            <a href="#" aria-label="LinkedIn">
              <FaLinkedinIn />
  
            </a> */}
          </div>
        </div>
        <div class="text-sm md:text-base grid grid-cols-3 gap-4 md:gap-10">
          <div>
            <h3 class="font-semibold">Students</h3>
            <ul>
              <li>
                <Link to="/resources/undergrad">Undergraduate</Link>
              </li>
              <li>
                <Link to="/resources/postgrad">Postgraduate</Link>
              </li>
              <li>
                <Link to="/what-we-offer">What we offer?</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 class="font-semibold">Work with us</h3>
            <ul>
              <li>
                <Link to="/beamentor">Mentors</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 class="font-semibold">Company</h3>
            <ul>
              <li>
                <Link to="/who-we-are">Who we are?</Link>
              </li>
              <li>
                <Link to="/why-choose-us">Why choose us?</Link>
              </li>
              <li>
                <a href="mailto:support@uni-bridge.com">Contact us</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="text-center text-sm mt-5">
        <Link to="/terms-and-conditions">
          Terms of Service|Privacy & Cookie Notice
        </Link>
        {/* <a href="#">Terms of Service</a> |
        <a href="#">Privacy & Cookie Notice</a> */}
      </div>
      <p class="text-center text-xs mt-3">© 2024 Unibridge</p>
    </footer>
  );
}

export default Footer;
