import React, { useState } from "react";
import Unicam from "../../assets/images/Uni_Cam.jpg";
import supabase from "../../config/supabaseClient";
import PersonalInfo from "../../components/SignupFormSteps/PersonalInfo";
import ProfessionalBackground from "../../components/SignupFormSteps/ProfessionalBackground";
import MenteePreferences from "../../components/SignupFormSteps/MenteePreferences";
import { countries } from "../../data/Countries";
import { languages } from "../../data/Languages";
import { Courses } from "../../data/Courses";
import { Degrees } from "../../data/Degrees";
import { Universitylist } from "../../data/Universitylist";
import { StudyStartOptions } from "../../data/Studystartoptions";
import Resizer from "react-image-file-resizer"; // Import Resizer
import swal from "sweetalert2";

const initialFormData = {
  Name: "",
  email: "",
  nationality: "",
  countryOfResidence: "",
  languagesSpoken: [],
  About_yourself: "",
  profile_pic: null,
  careerPreferences: {
    degreeAspiration: "",
    studyStart: "",
    courseDecided: "",
    courseName: "",
    countryPreference: "",
    universityPreferences: [],
  },
  education: [
    {
      region: "",
      school: "",
      degree: "",
      fieldOfStudy: "",
      topic: "",
      funding: "",
      funding_name: "",
      currentlyStudying: false,
      startDate: null,
      endDate: null,
      CGPA: "",
    },
  ],
  experience: [
    {
      jobTitle: "",
      company: "",
      country: "",
      areaOfWork: "",
      currentPosition: false,
      workperiod: null,
    },
  ],
  websites: {
    linkedin: "",
    researchurl: "",
    website: "",
  },
  CV: "",
};

const Findmentorsignup = () => {
  const [step, setStep] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState("");
  const [inputuniValue, setInputUniValue] = useState("");

  const validateStep = () => {
    let isValid = true;
    let message = "";

    switch (step) {
      case 1:
        if (formData.Name.trim() === "") {
          isValid = false;
          message = "Name is required.";
          break;
        }
        if (formData.email.trim() === "") {
          isValid = false;
          message = "Email is required.";
          break;
        }
        if (formData.languagesSpoken.length === 0) {
          isValid = false;
          message = "At least one language must be selected.";
          break;
        }
        break;
      case 2:
        if (formData.careerPreferences.degreeAspiration === "") {
          isValid = false;
          message = "Degree aspiration is required.";
          break;
        }
        if (formData.careerPreferences.studyStart === "") {
          isValid = false;
          message = "Study start is required.";
          break;
        }
        if (formData.careerPreferences.courseDecided === "") {
          isValid = false;
          message = "Course decided is required.";
          break;
        }
        // Add more validations as needed for step 2
        break;
      default:
        isValid = false;
        message = "Invalid step.";
    }

    if (!isValid) {
      swal.fire({
        icon: "error",
        title: "Oops...",
        text: message,
      });
    }
    return isValid;
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (validateStep()) {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrevious = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "profile_pic" && files && files[0]) {
      const file = files[0];

      // Validate file type
      if (!file.type.startsWith("image/")) {
        setError("Please upload a valid image file.");
        return;
      }

      setError(""); // Clear previous errors

      if (file.size > 100 * 1024) {
        // If file size > 100KB
        Resizer.imageFileResizer(
          file,
          400, // maxWidth
          400, // maxHeight
          "JPEG", // compressFormat
          80, // quality
          0, // rotation
          (uri) => {
            // Update formData with resized image
            setFormData((prevData) => ({
              ...prevData,
              profile_pic: uri,
            }));
          },
          "base64" // outputType
        );
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFormData((prevData) => ({
            ...prevData,
            profile_pic: reader.result,
          }));
        };
        reader.readAsDataURL(file);
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleAddLanguage = () => {
    if (
      languages.includes(inputValue) &&
      !formData.languagesSpoken.includes(inputValue)
    ) {
      setFormData((prevData) => ({
        ...prevData,
        languagesSpoken: [...prevData.languagesSpoken, inputValue],
      }));
      setInputValue("");
    }
  };

  const handleRemoveLanguage = (languageToRemove) => {
    setFormData((prevData) => ({
      ...prevData,
      languagesSpoken: prevData.languagesSpoken.filter(
        (language) => language !== languageToRemove
      ),
    }));
  };

  const handleRemoveUniversity = (universityToRemove) => {
    setFormData({
      ...formData,
      careerPreferences: {
        universityPreferences:
          formData.careerPreferences.universityPreferences.filter(
            (university) => university !== universityToRemove
          ),
      },
    });
  };

  const handleArrayChange = (index, field, value, section) => {
    if (section === "websites") {
      setFormData((prevData) => ({
        ...prevData,
        websites: { ...prevData.websites, [field]: value },
      }));
    } else {
      const updatedArray = [...formData[section]];
      if (index !== null && index !== undefined) {
        updatedArray[index][field] = value;
      }
      setFormData((prevData) => ({ ...prevData, [section]: updatedArray }));
    }
  };

  const handleAddField = (section, emptyField) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: [...prevData[section], emptyField],
    }));
  };

  const handleDeleteField = (index, section) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: prevData[section].filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Comprehensive validation across all steps
    const allStepsValid =
      formData.Name.trim() !== "" &&
      formData.email.trim() !== "" &&
      formData.languagesSpoken.length > 0 &&
      formData.careerPreferences.degreeAspiration !== "" &&
      formData.careerPreferences.studyStart !== "" &&
      formData.careerPreferences.courseDecided !== "";

    if (!allStepsValid) {
      swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please ensure all required fields are filled out before submitting.",
      });
      return;
    }

    try {
      // Check if user exists

      const { data: existingUser, error: fetchError } = await supabase
        .from("users")
        .select("user_id, type, name")
        .eq("email", formData.email)
        .single();

      if (fetchError) {
        if (fetchError.details === "The result contains 0 rows") {
          swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No user found with this email.",
          });
        } else if (fetchError.status === 406) {
          swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Email does not match any existing user.",
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Error fetching user, please try again.",
          });
        }
        return;
      }

      if (existingUser.type && existingUser.name) {
        swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The email you entered is already associated with a user. To make changes, login and go to the edit profile page.",
        });
        return;
      }

      const userId = existingUser.user_id;

      const { error: userError } = await supabase
        .from("users")
        .update({
          name: formData.Name,
          nationality: formData.nationality,
          country_of_residence: formData.countryOfResidence,
          about_yourself: formData.About_yourself,
          profile_pic: formData.profile_pic,
          type: "Mentee",
        })
        .eq("email", formData.email);

      if (userError) {
        console.error("Error updating user:", userError);
        return;
      }

      const languageInserts = formData.languagesSpoken.map((language) => ({
        user_id: userId,
        language_name: language,
      }));

      const { error: languageError } = await supabase
        .from("user_languages")
        .insert(languageInserts);

      if (languageError) {
        console.error("Error inserting languages:", languageError);
      }

      const educationInserts = formData.education.map((edu) => ({
        user_id: userId,
        school: edu.school,
        degree: edu.degree,
        field_of_study: edu.fieldOfStudy,
        start_date: edu.startDate || null,
        end_date: edu.endDate || null,
        cgpa: edu.CGPA ? edu.CGPA : null,
        region: edu.region,
        topic: edu.topic,
        funding: edu.funding,
        funding_name: edu.funding_name,
        currently_studying: edu.currentlyStudying || false,
      }));

      const { error: educationError } = await supabase
        .from("user_education")
        .insert(educationInserts);

      if (educationError) {
        console.error("Error inserting education records:", educationError);
      }

      const experienceInserts = formData.experience.map((exp) => ({
        user_id: userId,
        job_title: exp.jobTitle,
        company: exp.company,
        country: exp.country,
        area_of_work: exp.areaOfWork,
        current_position: exp.currentPosition,
        work_period: exp.workperiod || 0,
      }));

      const { error: experienceError } = await supabase
        .from("user_experience")
        .insert(experienceInserts);

      if (experienceError) {
        console.error("Error inserting experience records:", experienceError);
      }

      // Insert websites
      const websiteInsert = {
        user_id: userId,
        linkedin: formData.websites.linkedin,
        research_url: formData.websites.researchurl,
        website_url: formData.websites.website,
      };

      const { error: websiteError } = await supabase
        .from("user_website")
        .insert([websiteInsert]);

      if (websiteError) {
        console.error("Error inserting websites:", websiteError);
      }

      const MenteeCareerPreference = {
        user_id: userId,
        aspire_degree: formData.careerPreferences.degreeAspiration,
        study_start: formData.careerPreferences.studyStart,
        course_decided: formData.careerPreferences.courseDecided,
        course_name: formData.careerPreferences.courseName,
        cv_link: formData.careerPreferences.CV,
      };

      const UniversityPreferences =
        formData.careerPreferences.universityPreferences.map((university) => ({
          user_id: userId,
          university_name: university,
        }));

      const { error: UniPrefError } = await supabase
        .from("umentee_university_preference")
        .insert(UniversityPreferences);

      if (UniPrefError) {
        console.error("Error inserting career preferences:", UniPrefError);
      }
      const { error: CareerError } = await supabase
        .from("umentee_career_preferences")
        .insert([MenteeCareerPreference]);

      if (CareerError) {
        console.error("Error inserting career preferences:", CareerError);
      }

      setFormData(initialFormData);
      setInputValue("");
      setStep(1);

      console.log("User and related data inserted successfully");
      swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data submitted successfully!",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong! Please try again.",
      });
    }
  };

  return (
    <div className="pt-16 min-h-screen">
      <div
        className="bg-cover bg-center min-h-screen flex w-full items-center justify-center"
        style={{
          backgroundImage: `url(${Unicam})`,
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          backgroundBlendMode: "color",
        }}
      >
        <div className="max-w-3xl my-10">
          <form
            onSubmit={handleSubmit}
            className="p-6 rounded-lg shadow-lg"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              backgroundBlendMode: "color",
            }}
          >
            <h2 className="text-3xl font-bold mb-7 text-center text-primary">
              {step === 1 && "Personal Information"}
              {step === 2 && "Career Preferences"}
              {step === 3 && "Professional and Educational Background"}
            </h2>

            {step === 1 && (
              <PersonalInfo
                formData={formData}
                handleChange={handleChange}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleAddLanguage={handleAddLanguage}
                handleRemoveLanguage={handleRemoveLanguage}
                languages={languages}
                countries={countries}
              />
            )}
            {step === 2 && (
              <MenteePreferences
                formData={formData}
                setFormData={setFormData}
                inputuniValue={inputuniValue}
                setInputUniValue={setInputUniValue}
                handleRemoveUniversity={handleRemoveUniversity}
                Degrees={Degrees}
                Courses={Courses}
                StudyStartOptions={StudyStartOptions}
                Universitylist={Universitylist}
                countries={countries}
              />
            )}

            {step === 3 && (
              <ProfessionalBackground
                formData={formData}
                handleArrayChange={handleArrayChange}
                handleAddField={handleAddField}
                handleDeleteField={handleDeleteField}
                countries={countries}
                universityList={Universitylist}
                degrees={Degrees}
                courses={Courses}
                type="Mentee"
              />
            )}

            <div className="flex justify-between mt-6">
              {step > 1 && (
                <button
                  type="button"
                  onClick={handlePrevious}
                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 hover:scale-105 transition duration-300"
                >
                  Previous
                </button>
              )}
              {step < 3 ? (
                <button
                  type="button"
                  onClick={handleNext}
                  className="px-4 py-2 bg-secondary-100 text-white rounded hover:scale-105 transition duration-300"
                >
                  Next
                </button>
              ) : (
                <button
                  type="submit"
                  className="px-4 py-2 bg-tertiary-100 text-white rounded hover:bg-tertiary-300"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Findmentorsignup;
