import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Google from "../assets/images/Google.svg";
import supabase from "../config/supabaseClient";
import swal from "sweetalert2";

function Login() {
  let navigate = useNavigate();
  const [credential, setCredential] = useState({ email: "", password: "" });
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    const { data, error } = await supabase.auth.signInWithPassword({
      email: credential.email,
      password: credential.password,
    });

    if (error) {
      setError(error.message);
    } else {
      console.log("User logged in:", data);
      const { data: session, error: sessionError } =
        await supabase.auth.setSession({
          access_token: data.session.access_token,
          refresh_token: data.session.refresh_token,
        });
      if (sessionError) {
        console.error("Error setting session:", sessionError);
      } else {
        console.log("Session set successfully:", session);
      }
      navigate("/");
      window.location.reload();
    }
  };

  const handleGoogleLogin = async (e) => {
    e.preventDefault();
    let { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
    });
  };

  return (
    <div className="pt-16 bg-stone-100">
      <div className="md:w-3/5 flex items-center justify-center h-lvh px-5 mx-auto bg-white">
        <div className="w-full max-w-md px-8">
          <h2 className="text-3xl font-semibold my-2">Log in</h2>
          <div className="border border-spacing-0 border-secondary-200 opacity-40 mb-6"></div>
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label
                htmlFor="username"
                className="block text-sm font-semibold text-gray-700 mb-2"
              >
                USERNAME
              </label>
              <input
                type="email"
                id="username"
                value={credential.email}
                onChange={(e) =>
                  setCredential({ ...credential, email: e.target.value })
                }
                placeholder="email@address.com"
                className="mt-1 block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="password"
                className="block text-sm font-semibold text-gray-700 mb-2"
              >
                PASSWORD
              </label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={credential.password}
                onChange={(e) =>
                  setCredential({ ...credential, password: e.target.value })
                }
                placeholder="**********"
                className="mt-1 block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className=" pr-3 flex items-center text-sm leading-5"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
              {error && (
                <div className="text-red-500 text-sm mt-2">{error}</div>
              )}
            </div>
            {/* <div className="flex items-center mb-6">
              <input
                type="checkbox"
                id="remember"
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label
                htmlFor="remember"
                className="ml-2 block text-sm text-gray-900"
              >
                Remember me
              </label>
            </div> */}
            <div className="">
              <button
                type="submit"
                className="w-full bg-primary text-white font-bold px-4 py-2 rounded-md hover:scale-105 transition duration-300"
              >
                Log in
              </button>
              <div className="text-center mt-4">
                <button
                  type="button"
                  className="text-secondary-100 hover:text-blue-500 underline"
                  onClick={async () => {
                    const { error } = await supabase.auth.resetPasswordForEmail(
                      credential.email
                    );
                    if (error) {
                      setError(error.message);
                      swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error.message,
                      });
                    } else {
                      swal.fire({
                        icon: "success",
                        title: "Email Sent",
                        text: "Check your email for the password reset link.(Make sure it is the right email address)",
                      });
                    }
                  }}
                >
                  Forgot password?
                </button>
              </div>
              <div className="text-center my-4">
                Don't have an account?
                <Link to="/signup" className="font-bold text-secondary-100">
                  {" "}
                  Signup{" "}
                </Link>
                or log in using
              </div>
              <div className="flex justify-center space-x-4 mt-2">
                <button
                  onClick={handleGoogleLogin}
                  className="w-10 h-10 flex items-center justify-center rounded-full border border-gray-300 shadow-sm"
                >
                  <img src={Google} alt="Google" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
