import { useEffect, useState } from "react";

function Testimonials() {
  const [current, setCurrent] = useState(0);

  const slides = [
    {
      id: 1,
      title:
        "I decided to pursue higher education in the UK. I had no knowledge of the application process but a dream. I was a little sceptical about this because of my low graduation marks. ",
      person: "ANHAD SINGH BHATIA",
      course: "Master in data science",
      university: "SHEFFIELD UNIVERSITY,UK",
      fullText:
        "almost a year and a half ago, I decided to pursue higher education in the UK. I had no knowledge of the application process but a dream. I was a little sceptical about this because of my low graduation marks. It did not seem easy to get into one of the top universities in the UK.\n\nThen, I was fortunate enough to avail myself of Gurashish's mentorship. I found him through LinkedIn, and I was absolutely impressed by his qualifications and experience. He assured me that, despite my low marks, I have a promising profile. He asked me to include all my achievements, internship experiences, and portfolio projects in my CV and statement of purpose.\n\nI started applying with the prepared documents. I applied to 10 universities, out of which I got offers from 7 universities. This seemed like magic, which boosted my confidence. For finalising a university, I followed his advice of connecting with students and professors to query about the courses and their modules. I analysed each model with my career aspirations. This helped me finalise the University of Sheffield.\n\nAfter this, the visa stage was crucial. He guided me through the documents to apply and the preparation of my cover letter. I received my visa within two weeks without any hurdles or inconveniences.\n\nI am thankful for Gurashish's mentorship. His exceptional and diverse knowledge assisted me in pursuing my dreams. ",
    },

    {
      id: 2,
      title:
        "Guru was incredibly helpful in answering all my queries about accommodation at St Edmund's College, Cambridge University, before I joined.",
      person: "AMINA ALI",
      course: "MBBChir Medicine",
      university: "University of Cambridge,UK",
      fullText:
        "Guru was incredibly helpful in answering all my queries about accommodation at St Edmund's College, Cambridge University, before I joined. He shared a detailed video showcasing the different grades of student accommodation, which was particularly helpful for us, since we couldn’t visit in person due to the COVID pandemic. Thanks to his efforts, I felt well-prepared to choose the right accommodation, and his continued support during my first term made settling in much easier.",
    },

    {
      id: 3,
      title:
        "I had the pleasure of working with Guru when I was applying to Cambridge and his guidance made a huge difference in my decision-making process.",
      person: "FAUSTINE PETRON",
      course: "MPhil in Sociology",
      university: "University of Cambridge,UK",
      fullText:
        "“ I had the pleasure of working with Guru when I was applying to Cambridge and his guidance made a huge difference in my decision-making process. One of the biggest concerns I had was choosing the right college and understanding the accommodation option amongst other things, but after just one conversation with him, I felt so much more confident and reassured. Guru took the time to listen to my concerns and break down the different factors I should consider, which helped me make a more informed choice.\n\nWhat I appreciated most was his detailed knowledge of Cambridge as a university and the city—he not only explained the pros and cons of different colleges but also gave me practical tips that I wouldn't have found elsewhere. His support felt personalized to my situation, which took a lot of the stress out of the application process. I walked away from our chat feeling much more prepared and excited about the future.\n\nI would highly recommend Guru’s services to anyone applying to Oxbridge. His experience and insights are invaluable, and he truly cares about helping students make the best decisions for themselves.”",
    },

    {
      id: 4,
      title:
        "I am deeply thankful to Gurashish Singh from the University of Cambridge for their exceptional guidance in crafting compelling Statements of Purpose (SOPs) and effectively shortlisting universities for my applications",
      person: "HARMAN SINGH JOLLY ",
      course: "Machine Learning Engineer | Master’s in CS",
      university: "Concordia University,Cananda",
      fullText:
        "I am deeply thankful to Gurashish Singh from the University of Cambridge for their exceptional guidance in crafting compelling Statements of Purpose (SOPs) and effectively shortlisting universities for my applications, long before tools like ChatGPT were available. Their expertise in understanding what top institutions seek and their ability to help articulate my unique strengths and goals made my applications truly stand out. Gurashish provided insightful, tailored feedback that transformed my SOPs into persuasive narratives, significantly enhancing my chances of acceptance. For anyone looking to navigate the competitive landscape of university applications, Gurashish’s support is invaluable and highly recommended",
    },

    {
      id: 5,
      title:
        "I am forever grateful to Gurashish for his incredible support and guidance in helping me navigate the complex process of gaining admission to the Master's in Science program at Sheffield University.",
      person: "NIKITA MISHRA",
      course: "Master's in Science",
      university: "University of Sheffield,UK",
      fullText:
        "I am forever grateful to Gurashish for his incredible support and guidance in helping me navigate the complex process of gaining admission to the Master's in Science program at Sheffield University. His invaluable advice and unwavering encouragement made the journey much smoother than I could have ever imagined.\n\nNot only did he assist me throughout the application process, but he also helped me settle into university life once I arrived. From helping me adjust to a new environment to offering practical advice on day-to-day matters, Gurashish was there every step of the way. I cannot thank him enough for his kindness, patience, and dedication, which made my transition into this new chapter of my life truly seamless.",
    },
    // Add more objects as needed
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (current === slides.length - 1) setCurrent(0);
      else setCurrent(current + 1);
    }, 4000);
    return () => clearInterval(interval);
  }, [current, slides.length]);

  const previousSlide = () => {
    if (current === 0) {
      setCurrent(slides.length - 1);
    } else {
      setCurrent(current - 1);
    }
  };

  const nextSlide = () => {
    if (current === slides.length - 1) setCurrent(0);
    else setCurrent(current + 1);
  };

  return (
    <div className="overflow-auto relative mx-auto md:w-[80%] testimonial">
      <div className="flex transition ease-out duration-300 py-14">
        <div
          key={slides[current].id}
          className="w-full flex flex-col px-12 md:px-32 md:py-28"
        >
          <h2 className="text-xl md:text-2xl italic mb-10">
            {`"${slides[current].title}"`}{" "}
          </h2>
          {/* <span>
            <input type="text" placeholder="type here" className="" />
            <button
              type="button"
              className="bg-primary text-white py-2 px-2 text-sm font-semibold rounded hover:scale-105"
              onClick={() =>
                document.querySelector(".Read").classList.toggle("hidden")
              }
            >
              Read more
            </button>
            <div className="hidden justify-end font-semibold Read">
              {slides[current].fullText}
            </div>
          </span> */}

          <div className="flex justify-end font-semibold">
            {slides[current].person}
          </div>
          <div className="flex justify-end text-right font-semibold">
            {slides[current].course}
          </div>
          <div className="flex justify-end text-right font-semibold">
            {slides[current].university}
          </div>
        </div>
      </div>
      <div className="absolute top-0 h-full w-full justify-between items-center flex text-white px-5 text-3xl">
        <button onClick={previousSlide}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#392A48"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m14 7l-5 5l5 5"
            />
          </svg>
        </button>
        <button onClick={nextSlide}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <path
              fill="#392A48"
              d="m13.172 12l-4.95-4.95l1.414-1.413L16 12l-6.364 6.364l-1.414-1.415z"
            />
          </svg>
        </button>
      </div>

      <div className="absolute bottom-0 py-4 flex justify-center gap-3 w-full">
        {slides.map((s, i) => {
          return (
            <div
              onClick={() => {
                setCurrent(i);
              }}
              key={"circle" + s.id}
              className={`rounded-full w-3 h-3 cursor-pointer  ${
                i === current ? "bg-primary" : "bg-gray-500"
              }`}
            ></div>
          );
        })}
      </div>
    </div>
  );
}

export default Testimonials;
