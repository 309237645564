import React from "react";
import { Link } from "react-router-dom";
import Unilog from "../assets/images/Unilogo.png";
import Stepping_hero from "../assets/images/stepping_hero.svg";
import Subhero from "../assets/images/subhero2.svg";
import Mnetor_pic from "../assets/images/mentor2.jpg";
import Offerings from "../assets/images/Offerings.jpeg";
// import Testimonials from "./Testimonials";
import rectangle from "../assets/images/rectangle.svg";
// import down_icon from "../assets/images/ForResources/down_icon.svg";
// import Mentor_offering2 from "../assets/images/login.svg";
import "./Subherosection.css";
import { FaArrowsDownToPeople } from "react-icons/fa6";
import {
  FaPencilAlt,
  FaGraduationCap,
  FaCalendarAlt,
  FaTrophy,
  FaPassport,
} from "react-icons/fa";
import { MdFlightTakeoff } from "react-icons/md";
import { LiaUniversitySolid } from "react-icons/lia";

function Subherosection() {
  function Card({ icon, title, description }) {
    return (
      <div className="bg-stone-200 rounded-lg p-6 ">
        <div className="bg-secondary-500 rounded-full p-3 mr-4 flex h-20 w-20">
          {React.cloneElement(icon, {
            className: "text-white text-5xl ml-1 text-center",
          })}
        </div>
        <div className="text-left mt-6 mb-4">
          <h3 className="text-xl font-bold mb-4 text-primary">{title}</h3>
          <p className="text-primary pl-2">{description}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex top-0 flex-col w-full">
      <div className=" my-10 md:mt-20 flex flex-col">
        <div className=" flex flex-col md:flex-row px-8 md:px-16 ">
          <div className="md:w-1/3 text-secondary-700  mx-10 hidden md:flex md:flex-col items-center justify-center">
            <span className="font-light text-center translate-x-2">"Over</span>
            <h1 className="text-6xl font-bold text-center my-2">90%</h1>
            <h2 className="font-semibold text-xl text-center my-2">
              of mentors on the Unibridge
            </h2>
            <h2 className=" text-lg text-center my-2">
              come from prestigious global universities"
            </h2>
          </div>
          <div className="text-lg text-secondary-700 text-center font-semibold mb-8 md:hidden">
            "Over 90% of mentors on the Unibridge come from prestigious
            universities "{" "}
          </div>
          <div className="md:w-2/3 scale-110 md:scale-100 md:mr-10 text-center">
            <img
              src={Unilog}
              alt="uni-logos"
              className="flex item-center justify-center"
            />
          </div>
        </div>
        <div className="relative  bg-opacity-90 text-grey-700  w-full flex-grow  md:px-20 md:py-32">
          <img
            src={rectangle}
            alt="rectangle"
            className="hidden md:block absolute top-0 left-0  w-full h-full object-cover "
          />
          <h1 className="text-3xl md:text-6xl font-bold text-center text-secondary-700 my-10 translate-y-2">
            Your Journey with us
          </h1>
          <div className="flex flex-wrap justify-center">
            <div className="flex flex-col mx-8 space-y-6 md:w-2/5">
              <div className="md:h-52 mt-10 text-sm md:text-base flex bg-white rounded-lg overflow-hidden shadow-md md:-translate-x-1/3 ">
                <div className=" bg-secondary-500 w-1/4 flex items-center justify-center text-white text-6xl font-bold">
                  🏆
                </div>
                <div className="p-4 w-3/4 bg-stone-100">
                  <h2 className="text-xl font-bold mb-2">
                    Customized Support for Every Dream
                  </h2>
                  <p className="text-gray-500">
                    No matter your ambition—whether it's a prestigious
                    university or studying abroad—receive personalized
                    mentorship tailored to your unique profile.
                  </p>
                </div>
              </div>

              <div className="md:h-52 mt-10 text-sm md:text-base flex bg-white rounded-lg overflow-hidden shadow-md ">
                <div className="bg-secondary-500 w-1/4 flex items-center justify-center text-white text-6xl font-bold">
                  🤝
                </div>
                <div className="p-4 w-3/4 bg-stone-100">
                  <h2 className="text-xl font-bold mb-2">
                    Real-World Experience at Your Fingertips
                  </h2>
                  <p className="text-gray-500">
                    Learn from mentors who have successfully navigated their own
                    journeys, offering you invaluable insights and proven
                    strategies.
                  </p>
                </div>
              </div>

              <div className="md:h-52 mt-10 text-sm md:text-base flex bg-white rounded-lg overflow-hidden shadow-md md:translate-x-1/3">
                <div className="bg-secondary-500 w-1/4 flex items-center justify-center text-white text-6xl font-bold">
                  🚀
                </div>
                <div className="p-4 w-3/4 bg-stone-100">
                  <h2 className="text-xl font-bold mb-2">
                    Propel Your Future Forward
                  </h2>
                  <p className="text-gray-500">
                    Equip yourself with the tools and strategies to shine in
                    your applications and stand out in a competitive landscape!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 className="text-3xl font-bold font-center text-primary mx-auto md:mb-12 md:text-6xl">
        Process Overview
      </h1>
      <div className="min-h-96 ml-8 my-10 md:mt-10 flex flex-col md:flex-row">
        <div className="md:w-3/5 px-6 my-2 md:my-10 flex justify-center items-center">
          <img
            src={Subhero}
            alt="stepping_up icon"
            className="flex item-center justify-center"
          />
        </div>
        <div class="flex flex-col items-center justify-center md:mx-auto md:w-3/5  mx-8 space-y-8">
          <div class="card bg-secondary-500 sticky top-24 rotate-2 p-4 rounded-lg shadow-md md:w-4/5 h-50">
            <h2 class="text-xl font-bold">01</h2>
            <h3 class="mb-4 text-2xl font-semibold">Sign up for an account</h3>
            <p>Create your personal account to start using our services.</p>
          </div>
          <div class="card bg-secondary-600 sticky top-24 -rotate-2 p-4 rounded-lg shadow-md md:w-4/5 h-50">
            <h2 class="text-xl font-bold">02</h2>
            <h3 class="mb-4 text-2xl font-semibold">Choose a mentor</h3>
            <p>
              Select from a list of qualified mentors who best fit your academic
              needs.
            </p>
          </div>
          <div class="card bg-secondary-500 sticky top-24 rotate-2 p-4 rounded-lg shadow-md md:w-4/5 h-50">
            <h2 class="text-xl font-bold">03</h2>
            <h3 class="mb-4 text-2xl font-semibold">Book a session</h3>
            <p>
              Book a mentorship session at your convenience to get personalized
              guidance and support for your university studies.
            </p>
          </div>
          <div class="card bg-secondary-600 sticky top-24 -rotate-2 p-4 rounded-lg shadow-md md:w-4/5 h-50">
            <h2 class="text-xl font-bold">04</h2>
            <h3 class="mb-4 text-2xl font-semibold">Track your progress</h3>
            <p>
              Monitor your academic progress and receive feedback from your
              mentor to stay on track towards achieving your goals.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-secondary-900 bg-opacity-90 h-52 flex flex-wrap justify-center px-4 my-12 mx-8 md:mx-20 rounded-xl text-white">
        <div className="flex flex-col items-center justify-center md:w-1/2">
          <h1 className="text-3xl md:text-6xl font-bold mb-2">
            Connect with Us
          </h1>
          <h3 className="text-center mb-4">
            Join our network to access top-notch mentors.
          </h3>
        </div>
        <div className="text-center flex items-center justify-center md:w-1/2">
          <Link to="/signup">
            <button className="bg-primary text-white font-bold py-2 px-4 rounded transition duration-300 transform hover:scale-105">
              Join Us
            </button>
          </Link>
        </div>
      </div>

      {/* <div className="bg-stone-200 flex h-20 px-4 justify-center items-center text-primary  text-2xl md:text-3xl font-bold">
        Begin your journey with us
        <Link to="/signup">
          <button className=" text-white bg-primary ml-2 md:py-2  text-sm md:text-base font-semibold px-4 rounded  transition duration-300 transform hover:scale-105">
            Join now
          </button>
        </Link>
      </div> */}
      <div className="bg-secondary-700">
        <div className="text-3xl font-bold text-center text-white mx-auto my-12 md:text-6xl">
          Mentor Offerings
        </div>

        <div className="mx-8 md:mx-32 md:mt-10 ">
          <div className="md:flex w-full mb-20">
            <div className=" w-full md:w-1/2 md:py-8 px-6 ">
              <h1 className="text-3xl font-bold  text-white mx-auto my-4 md:mt-10  md:text-6xl ">
                Stand out, <br />
                from the crowd
              </h1>
              <h3 className="text-md  text-white md:font-semibold md:mr-10  md:text-lg">
                Mentors on Unibridge are handpicked from the top universities
                and have a proven track record of success in their respective
                fields.
              </h3>
            </div>

            <div className=" w-full md:w-1/2 py-8 md:py-0 md:px-4">
              <div className="w-full flex justify-center items-center">
                <img
                  src={Offerings}
                  alt="stepping_up icon"
                  className="flex item-center justify-center rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full px-4 md:mr-12 grid grid-cols-1  gap-16">
          <div className="w-full px-4 md:px-28 md:py-12 ">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              <Card
                icon={
                  <FaArrowsDownToPeople className="text-4xl mb-4 text-secondary-700" />
                }
                title="Personalized Guidance"
                description={
                  <ul className="list-disc">
                    <li>Share similar experiences</li>
                    <li>Provide tailored advice</li>
                    <li>Help navigate challenges</li>
                  </ul>
                }
              />
              <Card
                icon={
                  <FaPencilAlt className="text-4xl mb-4 text-secondary-700" />
                }
                title="University Selection"
                description={
                  <ul className="list-disc">
                    <li>Identify best-fit institutions</li>
                    <li>Evaluate program strengths</li>
                    <li>Assess campus culture</li>
                  </ul>
                }
              />

              <Card
                icon={
                  <FaGraduationCap className="text-4xl mb-4 text-secondary-700" />
                }
                title="Application Mastery"
                description={
                  <ul className="list-disc">
                    <li>Review and refine essays</li>
                    <li>Prepare for interviews</li>
                    <li>Strengthen overall application</li>
                  </ul>
                }
              />

              <Card
                icon={
                  <FaCalendarAlt className="text-4xl mb-4 text-secondary-700" />
                }
                title="Scholarship Strategy"
                description={
                  <ul className="list-disc">
                    <li>Explore funding opportunities</li>
                    <li>Scholarship applications help</li>
                    <li>Advise on financial planning</li>
                  </ul>
                }
              />
            </div>
          </div>

          <div className=" flex flex-wrap px-4 py-4 md:mx-32">
            <div className="w-full md:w-1/2 px-4 flex justify-center items-center">
              <img
                src={Stepping_hero}
                alt="mentor icon"
                className="flex item-center justify-center "
              />
            </div>

            <div className=" w-full md:w-1/2 py-8 px-6 md:pl-16 ">
              <h1 className="text-3xl font-bold  text-white mx-auto my-4 md:mt-10  md:text-6xl ">
                Empowering Students Every Step
              </h1>
              <h3 className="text-md  text-white md:font-semibold md:mr-10  md:text-lg">
                we are dedicated to guiding students through their academic and
                career journeys, ensuring their success and growth every step.
              </h3>
            </div>
          </div>

          <div className="w-full  md:mr-12 grid grid-cols-1 gap-16">
            <div className="w-full px-4 md:px-28 pb-12 md:pt-12 ">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                <Card
                  icon={
                    <FaPassport className="text-4xl mb-4 text-secondary-700" />
                  }
                  title="Visa Navigation"
                  description={
                    <ul className="list-disc">
                      <li>Create application checklist</li>
                      <li>Get insights on documents</li>
                      <li>Share tips for successful visa</li>
                    </ul>
                  }
                />

                <Card
                  icon={
                    <MdFlightTakeoff className="text-4xl mb-4 text-secondary-700" />
                  }
                  title="Pre-Departure Prep"
                  description={
                    <ul className="list-disc">
                      <li>Explore funding opportunities</li>
                      <li>Scholarship applications help</li>
                      <li>Advise on financial planning</li>
                    </ul>
                  }
                />

                <Card
                  icon={
                    <LiaUniversitySolid className="text-4xl mb-4 text-secondary-700" />
                  }
                  title="Campus Life advice"
                  description={
                    <ul className="list-disc">
                      <li>Guide through settling-in</li>
                      <li>Advise on campus resources</li>
                      <li>Help build social networks</li>
                    </ul>
                  }
                />

                <Card
                  icon={
                    <FaTrophy className="text-4xl mb-4 text-secondary-700" />
                  }
                  title="Career Development"
                  description={
                    <ul className="list-disc">
                      <li>Offer industry insights</li>
                      <li>Guide internship searches</li>
                      <li>Advice on networking strategies</li>
                    </ul>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <div className="bg-stone-100 my-10 py-10">
        {" "}
        <Testimonials />{" "}
      </div> */}

      <div className="flex top-0 md:flex-col w-full"></div>
    </div>
  );
}

export default Subherosection;
