export const StudyStartOptions = [
  "Select",
  "Not decided",
  "2025 Start",
  "2025 End",
  "2026 Start",
  "2026 End",
  "2027 Start",
  "2027 End",
  "2028 Start",
  "2028 End",
];
