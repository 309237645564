import React, { useEffect, useState } from "react";
import supabase from "../../config/supabaseClient";
import default_profile from "../../assets/images/default_profile.svg";
import EducationSection from "../../components/MentorProfile/EducationSection";
import ExperienceSection from "../../components/MentorProfile/ExperienceSection";
import WebsiteSection from "../../components/MentorProfile/WebsiteSection";
import AboutSection from "../../components/MentorProfile/AboutSection";
import EditEducationModal from "../../components/MentorProfile/EditEducationModal";
import EditExperienceModal from "../../components/MentorProfile/EditExperienceModal";
import EditWebsiteModal from "../../components/MentorProfile/EditWebsiteModal";
import EditAboutModal from "../../components/MentorProfile/EditAboutModal";
import Resizer from "react-image-file-resizer";

function Your_profile() {
  const [mentor, setMentor] = useState(null);
  const [formData, setFormData] = useState({
    Name: "",
    about_yourself: "",
    profile_pic: "",
    education: [
      {
        region: "",
        school: "",
        degree: "",
        fieldOfStudy: "",
        topic: "",
        funding: "",
        funding_name: "",
        cgpa: "",
        currentlyStudying: false,
        startDate: "",
        endDate: "",
        discipline: "",
      },
    ],
    experience: [
      {
        jobTitle: "",
        company: "",
        country: "",
        areaOfWork: "",
        currentPosition: false,
        workperiod: "",
      },
    ],
    websites: {
      linkedin: "",
      researchurl: "",
      website: "",
    },
  });
  const [isEditing, setIsEditing] = useState({
    education: false,
    experience: false,
    website: false,
    about: false,
    profilePic: false, // Added profilePic editing state
  });
  const [error, setError] = useState(""); // Added error state
  const [profilePicFile, setProfilePicFile] = useState(null); // State for selected file

  useEffect(() => {
    const fetchMentorProfile = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();

      try {
        const { data: mentorData, error: mentorError } = await supabase
          .from("users")
          .select("*")
          .eq("email", user.email)
          .single();

        if (mentorError) throw mentorError;

        const { data: educationData, error: educationError } = await supabase
          .from("user_education")
          .select("*")
          .eq("user_id", mentorData.user_id);

        if (educationError) throw educationError;

        const { data: experienceData, error: experienceError } = await supabase
          .from("user_experience")
          .select("*")
          .eq("user_id", mentorData.user_id);

        if (experienceError) throw experienceError;

        const { data: disciplinesData, error: disciplinesError } =
          await supabase
            .from("user_disciplines")
            .select("*")
            .eq("user_id", mentorData.user_id);

        if (disciplinesError) throw disciplinesError;

        const { data: websiteData, error: websiteError } = await supabase
          .from("user_website")
          .select("*")
          .eq("user_id", mentorData.user_id);

        if (websiteError) throw websiteError;

        setMentor({
          ...mentorData,
          userdata: mentorData,
          education: educationData,
          experience: experienceData,
          disciplines: disciplinesData,
          website: websiteData,
        });
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchMentorProfile();
  }, []);

  const FetchMenteePrefrences = async () => {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === "profile_pic" && files && files[0]) {
      const file = files[0];

      // Validate file type
      if (!file.type.startsWith("image/")) {
        setError("Please upload a valid image file.");
        return;
      }

      setError(""); // Clear previous errors

      if (file.size > 100 * 1024) {
        // If file size > 100KB
        Resizer.imageFileResizer(
          file,
          400, // maxWidth
          400, // maxHeight
          "JPEG", // compressFormat
          80, // quality
          0, // rotation
          (uri) => {
            // Update formData with resized image
            setFormData((prevData) => ({
              ...prevData,
              profile_pic: uri,
            }));
            setProfilePicFile(uri);
          },
          "base64" // outputType
        );
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFormData((prevData) => ({
            ...prevData,
            profile_pic: reader.result,
          }));
          setProfilePicFile(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSaveProfilePic = async () => {
    if (!profilePicFile) {
      setError("No image selected.");
      return;
    }

    const { error: updateError } = await supabase
      .from("users")
      .update({ profile_pic: profilePicFile })
      .eq("user_id", mentor.user_id);

    if (updateError) {
      console.error("Error updating profile picture:", updateError);
      setError("Failed to update profile picture.");
    } else {
      setMentor({ ...mentor, profile_pic: profilePicFile });
      setIsEditing({ ...isEditing, profilePic: false });
      setError("");
    }
  };

  if (!mentor) {
    return (
      <div className="pt-16 min-h-lvh">
        <div className="mx-6 md:mx-20">
          <h1 className="text-xl md:text-3xl font-bold text-center text-primary my-6">
            User Details
          </h1>
          <p>No mentor found.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-16 min-h-lvh">
      <div className="mx-6 md:mx-20">
        <h1 className="text-xl md:text-3xl font-bold text-center text-primary my-6">
          {mentor.type} Details
        </h1>
        <div className="flex flex-col">
          <div className="mx-10 flex flex-col md:flex-row">
            <div className="flex flex-col md:w-1/2 md:max-md">
              {/* Change Profile Picture Button */}
              <div className="flex justify-center my-4">
                <button
                  className="mt-4 px-4 py-2 bg-primary text-white rounded"
                  onClick={() =>
                    setIsEditing({ ...isEditing, profilePic: true })
                  }
                >
                  Change Profile Picture
                </button>
              </div>
              <img
                src={mentor.profile_pic || default_profile}
                alt={mentor.name}
                className="object-cover rounded-full h-52 w-52 mx-auto border-2"
              />
              <h2 className="text-center font-bold text-primary mt-4">
                {mentor.name}
              </h2>

              {mentor.experience.slice(0, 1).map((exp) => (
                <p
                  key={exp.id}
                  className="text-center font-bold text-primary mb-4"
                >
                  {exp.position} at {exp.company}
                </p>
              ))}
            </div>

            <div className="md:mx-6">
              <EducationSection
                education={mentor.education}
                type={mentor.type}
                onEdit={() => setIsEditing({ ...isEditing, education: true })}
                onAdd={() => setIsEditing({ ...isEditing, education: true })}
              />

              <ExperienceSection
                experience={mentor.experience}
                onEdit={() => setIsEditing({ ...isEditing, experience: true })}
                onAdd={() => setIsEditing({ ...isEditing, experience: true })}
              />

              <WebsiteSection
                website={mentor.website}
                onEdit={() => setIsEditing({ ...isEditing, website: true })}
              />
            </div>
          </div>

          <AboutSection
            about={mentor.about_yourself}
            onEdit={() => setIsEditing({ ...isEditing, about: true })}
          />

          {/* Edit Modals */}
          {isEditing.education && (
            <EditEducationModal
              userId={mentor.user_id}
              formData={formData}
              setFormData={setFormData}
              onClose={() => setIsEditing({ ...isEditing, education: false })}
              onSave={() => setIsEditing({ ...isEditing, education: false })}
            />
          )}
          {isEditing.experience && (
            <EditExperienceModal
              userId={mentor.user_id}
              formData={formData}
              setFormData={setFormData}
              onClose={() => setIsEditing({ ...isEditing, experience: false })}
              onSave={() => setIsEditing({ ...isEditing, experience: false })}
            />
          )}
          {isEditing.website && (
            <EditWebsiteModal
              userId={mentor.user_id}
              websites={mentor.website[0]}
              formData={formData}
              setFormData={setFormData}
              onClose={() => setIsEditing({ ...isEditing, website: false })}
              onSave={() => setIsEditing({ ...isEditing, website: false })}
            />
          )}
          {isEditing.about && (
            <EditAboutModal
              userId={mentor.user_id}
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              onClose={() => setIsEditing({ ...isEditing, about: false })}
              onSave={() => setIsEditing({ ...isEditing, about: false })}
            />
          )}

          {/* Edit Profile Picture Modal */}
          {isEditing.profilePic && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg w-11/12 md:w-1/3">
                <h2 className="text-2xl mb-4">Change Profile Picture</h2>
                {error && <p className="text-red-500 mb-2">{error}</p>}
                <input
                  type="file"
                  name="profile_pic"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="mb-4"
                />
                {formData.profile_pic && (
                  <img
                    src={formData.profile_pic}
                    alt="Preview"
                    className="object-contain rounded-xl max-h-40 max-w-40 mb-4"
                  />
                )}
                <div className="flex justify-end">
                  <button
                    className="px-4 py-2 bg-gray-300 rounded mr-2"
                    onClick={() =>
                      setIsEditing({ ...isEditing, profilePic: false })
                    }
                  >
                    Cancel
                  </button>
                  <button
                    className="px-4 py-2 bg-primary text-white rounded"
                    onClick={handleSaveProfilePic}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Your_profile;
