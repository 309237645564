import React from "react";
import { Link } from "react-router-dom";
import earth from "../../assets/images/ForAbout/earth.svg";
import Global_leader from "../../assets/images/ForAbout/Global_leader.svg";
import Community from "../../assets/images/ForAbout/Community.svg";
import Story from "../../assets/images/ForAbout/Story.svg";
import Class_mentors from "../../assets/images/ForAbout/Class_mentors.svg";
import Proven_success from "../../assets/images/ForAbout/Proven_success.svg";
import Tailored_guidance from "../../assets/images/ForAbout/Tailored_guidance.svg";
import Holistic from "../../assets/images/ForAbout/Holistic.svg";
import Chooseus from "../../assets/images/ForAbout/Chooseus.svg";
import Mission from "../../assets/images/ForAbout/Mission.svg";
// import Vision from "../../assets/images/ForAbout/Vision.svg";

function Whychooseus() {
  return (
    <div className="pt-16">
      <h1 className="text-3xl md:text-5xl text-secondary-100 bg-stone-100 font-bold text-center pt-10 pb-4">
        Why Choose Us?
      </h1>
      <div className="min-h-72 text-secondary-100 bg-stone-100 flex flex-col md:flex-row items-center justify-center md:py-16 px-8 md:px-16 ">
        <h3 className="text-lg text-center md:text-xl font-medium mb-8 px-10 md:px-32">
          We believe every student should be able to explore the world, expand
          their education, and realise their potential, no matter where they
          come from. <br /> <br /> And we’re here to help make that dream a
          reality, one student at a time.
        </h3>
        <img src={Chooseus} alt="career" className="md:w-1/2 md:pr-20" />
      </div>

      <section className="mb-6">
        <div className="flex w-full pb-12">
          {" "}
          <h3 className=" flex justify-end items-center  text-3xl md:text-5xl text-primary font-bold mb-2 w-1/3 px-8">
            Our Aim{" "}
          </h3>
          <div className="bg-primary h-20 w-2/3 "> </div>{" "}
        </div>

        <div className="min-h-72 flex flex-col md:flex-row items-center justify-center md:py-16 px-8 md:px-16 ">
          <img src={Mission} alt="mission" className="md:w-1/2 px-8" />
          <p className="text-secondary-700 text-center md:text-lg mx-auto px-4 md:px-24 py-8 md:py-16">
            At Unibridge, our mission is to empower students to achieve their
            academic ambitions and seize global professional opportunities by
            connecting them with mentors who have successfully navigated the
            same journey. We simplify the path to international education with
            relatable advice, personalized mentorship, and a platform that makes
            higher education accessible and attainable for everyone.
          </p>
        </div>
      </section>

      <section className="mb-6">
        <div className="flex w-full pb-12">
          <div className="bg-primary h-20  w-3/5 md:w-2/3 "> </div>{" "}
          <h3 className=" flex justify-start items-center  text-3xl md:text-5xl text-primary font-bold mb-2 w-2/5 md:w-1/3 pl-6">
            Our Vision{" "}
          </h3>
        </div>
        <div className="px-8">
          <div className="text-secondary-200 text-lg  md:text-xl font-bold mb-6 w-2/3 mx-auto text-center">
            Capturing the long-term aspiration and the overarching goal of every
            student aspiring for higher education abroad, Unibridge is for You!
          </div>

          <div className="flex flex-col md:flex-row px-8 py-10 md:py-16 md:px-24 md:space-x-12">
            <div className="bg-stone-200 rounded-lg p-6 my-6 flex flex-col items-center h-76 flex-1">
              <img src={earth} alt="earth-icon" className="h-20 w-20 mx-auto" />
              <h4 className="text-primary text-xl font-bold my-4 text-center">
                Equal World
              </h4>
              <p className="text-primary my-2 ">
                Create a world where every student, regardless of background,
                can access the tools needed to pursue higher education.
              </p>
            </div>
            <div className="bg-stone-200 rounded-lg p-6 my-6 flex flex-col items-center h-76 flex-1">
              <img
                src={Global_leader}
                alt="global-leader-icon"
                className="h-20 w-20 mx-auto"
              />
              <h4 className="text-primary text-xl font-bold my-4 ">
                Global Leader
              </h4>
              <p className="text-primary mb-2 ">
                To become a global leader in mentoring students by offering an
                inclusive, inspiring, and innovative platform.
              </p>
            </div>
            <div className="bg-stone-200 rounded-lg p-6 my-6 flex flex-col items-center h-76 flex-1">
              <img
                src={Community}
                alt="community-icon"
                className="h-20 w-20 mx-auto"
              />
              <h4 className="text-primary text-xl font-bold my-4 ">
                Supportive Community
              </h4>
              <p className="text-primary ">
                To foster a supportive community, for students to explore new
                cultures, broaden their horizons, and reach their full
                potential.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <hr className="border-t border-gray-500 opacity-35" /> */}

      <h3 className="my-4  text-2xl md:text-3xl text-primary font-bold pl-8 md:pl-20 ">
        Our Distinct Advantage
      </h3>

      <section className="my-6 mx-12 md:mx-20 grid gap-8 md:grid-cols-4">
        <div className="relative flip-card bg-secondary-700 text-white p-6 rounded-lg overflow-hidden h-72">
          <div className="flip-card-inner">
            <div className="flip-card-front text-center my-12">
              <img
                src={Class_mentors}
                alt="mentors-icon"
                className="h-20 w-20 mx-auto"
              />
              <h4 className="text-xl font-bold my-4 text-center">
                World-Class Mentors
              </h4>
            </div>
            <div className="flip-card-back p-4 my-12">
              <p>Our mentors hail from top global universities.</p>
            </div>
          </div>
        </div>
        <div className="relative flip-card bg-secondary-700 text-white p-6 rounded-lg overflow-hidden h-72">
          <div className="flip-card-inner">
            <div className="flip-card-front text-center my-12">
              <img
                src={Tailored_guidance}
                alt="guidance-icon"
                className="h-20 w-20 mx-auto"
              />
              <h4 className="text-xl font-bold my-4 text-center">
                Tailored Guidance
              </h4>
            </div>
            <div className="flip-card-back p-4 my-12">
              <p>
                Customised support based on your unique goals, strengths, and
                background.
              </p>
            </div>
          </div>
        </div>
        <div className="relative flip-card bg-secondary-700 text-white p-6 rounded-lg overflow-hidden h-72">
          <div className="flip-card-inner">
            <div className="flip-card-front text-center my-12">
              <img
                src={Holistic}
                alt="Holistic-icon"
                className="h-20 w-20 mx-auto"
              />
              <h4 className="text-xl font-bold my-4 text-center">
                Holistic Approach
              </h4>
            </div>
            <div className="flip-card-back p-4 my-12">
              <p>
                We cover every aspect of the application process, ensuring you
                are fully prepared to achieve your educational dreams.
              </p>
            </div>
          </div>
        </div>
        <div className="relative flip-card bg-secondary-700 text-white p-6 rounded-lg overflow-hidden h-72">
          <div className="flip-card-inner">
            <div className="flip-card-front text-center my-12">
              <img
                src={Proven_success}
                alt="success-icon"
                className="h-20 w-20 mx-auto"
              />
              <h4 className="text-xl font-bold mt-6 my-4 text-center">
                Proven Success
              </h4>
            </div>
            <div className="flip-card-back p-4 my-12">
              <p>
                Our approach has helped countless students gain admission to
                their dream universities and career opportunities.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="flex my-12 w-full justify-between">
        <div className="w-1/3 bg-primary h-20"></div>
        <h2 className="w-1/3 text-3xl md:text-5xl flex items-center justify-center mx-8 text-primary font-bold">
          Our Story
        </h2>
        <div className="w-1/3 bg-primary h-20"></div>
      </div>

      <section className="px-8 flex flex-col md:flex-row md:space-x-12">
        <div className="my-8 md:w-1/2">
          <img src={Story} alt=" story_image" />
        </div>

        <div className="md:w-1/2 flex flex-col justify-center md:px-20">
          <p className="text-primary mb-2">
            <span className="font-bold">
              Every great journey starts with a single thought.
            </span>{" "}
            <br /> <br /> Ours began when we realised how overwhelming and
            confusing applying for higher education abroad could be. As students
            ourselves, we struggled with complex admissions procedures, endless
            paperwork, and countless questions. More than once, we wished we had
            someone to guide us – not just through the formalities but to help
            make the experience enjoyable, engaging, and less daunting.
          </p>
          <p className="text-primary mb-2">
            This realisation sparked the idea for our mentorship platform, and
            more so, to ease out for what we discovered ourselves. Our founding
            team set out to create a space where students could connect with
            experienced mentors who had walked the same path and could provide
            practical advice, encouragement, and inspiration.
          </p>
        </div>
      </section>

      <div className="py-4 my-4 mb-8 mx-8 text-lg font-bold text-white bg-secondary-900 bg-opacity-90 rounded-xl">
        <div className="text-center mx-8 md:mx-32">
          As we continue to grow, inspire, and make higher education abroad
          accessible to all. Your journey is our passion – together, we can make
          it an unforgettable adventure.
        </div>
        <div className="flex mx-auto justify-center my-4">
          <Link to="/signup">
            <button className=" py-2 px-4 text-white bg-primary mx-auto   text-base  font-semibold  rounded  transition duration-300 transform hover:scale-105">
              Join us
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Whychooseus;
