import React from "react";
import { countries } from "../../data/Countries";
import { Courses } from "../../data/Courses";
import { Degrees } from "../../data/Degrees";
import { Universitylist } from "../../data/Universitylist";
import swal from "sweetalert2";
import supabase from "../../config/supabaseClient";
import { IoIosInformationCircleOutline } from "react-icons/io";

function EditEducationModal({
  userId,
  type,
  formData,
  setFormData,
  onClose,
  onSave,
}) {
  const updateEducation = async () => {
    const { error } = await supabase.from("user_education").insert({
      user_id: userId,
      school: formData.education.school,
      degree: formData.education.degree,
      region: formData.education.region,
      field_of_study: formData.education.fieldOfStudy,
      topic: formData.education.topic,
      cgpa: formData.education.cgpa,
      funding: formData.education.funding,
      funding_name: formData.education.funding_name,
      currently_studying: formData.education.currentlyStudying,
      start_date: formData.education.startDate,
      end_date: formData.education.endDate,
    });
    if (error) {
      console.error("Error updating education:", error);
    } else {
      swal.fire({
        title: "Success",
        text: "Education updated successfully, refresh the page to see the changes",
        icon: "success",
      });
      onSave();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 pt-28 pb-8">
      <div className="bg-white p-4 rounded shadow-lg md:w-1/2 overflow-y-auto h-[70vh]">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold text-primary">Edit Education</h2>
          <button
            onClick={onClose}
            className="bg-secondary-500 text-white px-4 py-2 rounded-md"
          >
            Close
          </button>
        </div>

        <div className="space-y-3 mt-4 mmd:x-6">
          <div>
            <label className="block text-gray-700">Country</label>
            <input
              type="text"
              name="country"
              placeholder="Search for a Country"
              value={formData.education.region}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  education: {
                    ...formData.education,
                    region: e.target.value,
                  },
                })
              }
              className="w-full p-2 border rounded"
              list="country-list"
            />
            <datalist id="country-list">
              {countries.map((country) => (
                <option key={country.code} value={country.name}>
                  {country.name}
                </option>
              ))}
            </datalist>
          </div>
          <div>
            <label className="block text-gray-700">School or University</label>
            <input
              type="text"
              name="school"
              value={formData.education.school}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  education: {
                    ...formData.education,
                    school: e.target.value,
                  },
                })
              }
              className="w-full p-2 border rounded"
              list="university-list"
            />
            <datalist id="university-list">
              {Universitylist.map((University) => (
                <option key={University} value={University}>
                  {University}
                </option>
              ))}
            </datalist>
          </div>
          <div>
            <label className="block text-gray-700">Degree</label>
            <select
              name="degree"
              value={formData.education.degree}
              className="w-full p-2 border rounded"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  education: {
                    ...formData.education,
                    degree: e.target.value,
                  },
                })
              }
            >
              {Degrees.map((degree) => (
                <option key={degree} value={degree}>
                  {degree}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-gray-700">Field of Study</label>
            <input
              type="text"
              name="fieldOfStudy"
              placeholder="Search the Field"
              value={formData.education.fieldOfStudy}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  education: {
                    ...formData.education,
                    fieldOfStudy: e.target.value,
                  },
                })
              }
              className="w-full p-2 border rounded"
              list="courses-list"
            />
            <datalist id="courses-list">
              {Courses.map((course) => (
                <option key={course.subject_id} value={course.subjects}>
                  {course.subjects}
                </option>
              ))}
            </datalist>
          </div>

          <div>
            <label className="block text-gray-700">Topic (optional)</label>
            <input
              type="text"
              name="topic"
              placeholder="Provide research topic"
              value={formData.education.topic}
              className="w-full p-2 border rounded"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  education: {
                    ...formData.education,
                    topic: e.target.value,
                  },
                })
              }
            />
          </div>

          <div>
            <label className="block text-gray-700">Funding</label>
            <select
              name="funding"
              value={formData.education.funding}
              className="w-full p-2 border rounded"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  education: {
                    ...formData.education,
                    funding: e.target.value,
                  },
                })
              }
            >
              <option value="">None</option>
              <option value="Partial">Partial</option>
              <option value="Full">Full</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-700">
              Scholarship Name (optional)
            </label>
            <input
              type="text"
              name="funding_name"
              placeholder="Enter scholarship name"
              value={formData.education.funding_name}
              className="w-full p-2 border rounded"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  education: {
                    ...formData.education,
                    funding_name: e.target.value,
                  },
                })
              }
            />
          </div>
          {console.log("type", type)}
          {type === "Mentee" && (
            <div className="">
              <label className="flex items-center text-gray-700 relative">
                CGPA
                <span className="ml-1 text-gray-500 cursor-pointer group relative">
                  <IoIosInformationCircleOutline />
                  <span className="absolute p-2 text-sm bg-white md:min-w-96 text-gray-800 rounded shadow-lg opacity-0 transition-opacity duration-300 group-hover:opacity-100 pointer-events-none">
                    The CGPA should be defined out of 10. If you have
                    percentage, convert on the scale of 10. Please provide your
                    current CGPA if you are studying.
                  </span>
                </span>
              </label>

              <input
                type="number"
                name="cgpa"
                value={formData.education.cgpa}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    education: {
                      ...formData.education,
                      cgpa: e.target.value,
                    },
                  })
                }
                className="w-full p-2 border rounded"
              />
            </div>
          )}
          <div>
            <label className="flex text-gray-700 pt-2 items-center">
              I am currently studying here
              <input
                type="checkbox"
                name="currentlyStudying"
                checked={formData.education.currentlyStudying}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    education: {
                      ...formData.education,
                      currentlyStudying: e.target.checked,
                    },
                  })
                }
                className="inline-flex ml-2 w-6 h-8"
              />
            </label>
          </div>
          <div>
            <label className="block text-gray-700">Start Date</label>
            <input
              type="date"
              name="startDate"
              value={formData.education.startDate}
              className="w-full p-2 border rounded"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  education: {
                    ...formData.education,
                    startDate: e.target.value,
                  },
                })
              }
            />
          </div>
          <div>
            <label className="block text-gray-700">
              End Date (or Expected)
            </label>
            <input
              type="date"
              name="endDate"
              value={formData.education.endDate}
              className="w-full p-2 border rounded"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  education: {
                    ...formData.education,
                    endDate: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button
            onClick={updateEducation}
            className="bg-primary text-white px-4 py-2 my-4 rounded-md"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditEducationModal;
