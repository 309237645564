import React from "react";

const MentorshipPreferences = ({
  formData,
  setFormData,
  mentorshipOptions,
}) => {
  const {
    menteeTypes,
    studyRegions,
    disciplines,
    formats,
    hoursPerMonth,
    Addtionalinformation,
  } = formData.mentorshipPreferences;

  const handleCheckboxChange = (e, category) => {
    const value = parseInt(e.target.value);
    const updatedCategory = e.target.checked
      ? [...formData.mentorshipPreferences[category], value]
      : formData.mentorshipPreferences[category].filter((id) => id !== value);

    setFormData({
      ...formData,
      mentorshipPreferences: {
        ...formData.mentorshipPreferences,
        [category]: updatedCategory,
      },
    });
  };

  return (
    <div className="space-y-4">
      {/* Mentee Types */}
      <div>
        <label className="block text-gray-700">
          Who would you like to mentor? <span className="text-red-600">*</span>
          <i className="flex text-sm">
            (Select all the groups you’d be willing to guide)
          </i>
        </label>
        <div className="flex flex-col">
          {mentorshipOptions.menteeTypes.map((type) => (
            <label
              key={type.id}
              className="inline-flex items-center cursor-pointer border py-1 px-2 mb-1 border-secondary-100 rounded-lg"
            >
              <input
                type="checkbox"
                name="menteeTypes"
                value={type.id}
                checked={menteeTypes.includes(type.id)}
                onChange={(e) => handleCheckboxChange(e, "menteeTypes")}
                className="form-checkbox h-5 w-5 text-gray-600 transition duration-150 ease-in-out"
              />
              <span className="ml-2 my-1 text-gray-700 sm:text-sm">
                {type.name}
              </span>
            </label>
          ))}
        </div>
      </div>

      {/* Study Regions */}
      <div>
        <label className="block text-gray-700">
          Which country or region can you guide students to study in?{" "}
          <span className="text-red-600">*</span>
          <i className="flex text-sm">(Select all that apply.)</i>
        </label>
        <div className="flex flex-col">
          {mentorshipOptions.studyRegions.map((region) => (
            <label
              key={region.id}
              className="inline-flex items-center cursor-pointer border py-1 px-2 mb-1 border-secondary-100 rounded-lg"
            >
              <input
                type="checkbox"
                name="studyRegions"
                value={region.id}
                checked={studyRegions.includes(region.id)}
                onChange={(e) => handleCheckboxChange(e, "studyRegions")}
                className="form-checkbox h-5 w-5 text-gray-600 transition duration-150 ease-in-out"
              />
              <span className="ml-2 my-1 text-gray-700 sm:text-sm">
                {region.name}
              </span>
            </label>
          ))}
        </div>
      </div>

      {/* Disciplines */}
      <div>
        <label className="block text-gray-700">
          What Disciplines Are You Comfortable Mentoring In?{" "}
          <span className="text-red-600">*</span>
        </label>
        <div className="flex flex-col">
          {mentorshipOptions.disciplines.map((discipline) => (
            <label
              key={discipline.id}
              className="inline-flex items-center cursor-pointer border py-1 px-2 mb-1 border-secondary-100 rounded-lg"
            >
              <input
                type="checkbox"
                name="disciplines"
                value={discipline.id}
                checked={disciplines.includes(discipline.id)}
                onChange={(e) => handleCheckboxChange(e, "disciplines")}
                className="form-checkbox h-5 w-5 text-gray-600 transition duration-150 ease-in-out"
              />
              <span className="ml-2 my-1 text-gray-700 sm:text-sm">
                {discipline.name}
              </span>
            </label>
          ))}
        </div>
      </div>

      {/* Mentorship Formats */}
      <div>
        <label className="block text-gray-700">
          Preferred Mentorship Format
        </label>
        <div className="flex flex-col">
          {mentorshipOptions.formats.map((format) => (
            <label
              key={format.id}
              className="inline-flex items-center cursor-pointer border py-1 px-2 mb-1 border-secondary-100 rounded-lg"
            >
              <input
                type="checkbox"
                name="formats"
                value={format.id}
                checked={formats.includes(format.id)}
                onChange={(e) => handleCheckboxChange(e, "formats")}
                className="form-checkbox h-5 w-5 text-gray-600 transition duration-150 ease-in-out"
              />
              <span className="ml-2 my-1 text-gray-700 sm:text-sm">
                {format.name}
              </span>
            </label>
          ))}
        </div>
      </div>

      {/* Hours Per Month */}
      <div>
        <label className="block text-gray-700">
          How Many Hours Per Month Can You Commit to Mentoring?{" "}
          <span className="text-red-600">*</span>
        </label>
        <select
          name="hoursPerMonth"
          value={hoursPerMonth}
          onChange={(e) =>
            setFormData({
              ...formData,
              mentorshipPreferences: {
                ...formData.mentorshipPreferences,
                hoursPerMonth: e.target.value,
              },
            })
          }
          className="w-full p-2 border rounded"
        >
          <option value="">Select hours per month</option>
          <option value="2">2 hours</option>
          <option value="4">4 hours</option>
          <option value="6">6 hours</option>
          <option value="8">8 hours</option>
          <option value="10+">10+ hours</option>
          <option value="Flexible">Flexible</option>
        </select>
      </div>

      {/* Additional Information */}
      <div>
        <label htmlFor="additionalInformation" className="text-gray-700 block">
          Additional information (optional: max 100 words)
        </label>
        <textarea
          name="Addtionalinformation"
          value={Addtionalinformation}
          onChange={(e) =>
            setFormData({
              ...formData,
              mentorshipPreferences: {
                ...formData.mentorshipPreferences,
                Addtionalinformation: e.target.value.slice(0, 100),
              },
            })
          }
          className="w-full p-2 border rounded"
          maxLength="100"
        />
      </div>
    </div>
  );
};

export default MentorshipPreferences;
