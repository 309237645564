import React from "react";
import { MdOutlineEdit, MdOutlineAddCircleOutline } from "react-icons/md";

function ExperienceSection({ experience, onEdit, onAdd }) {
  return (
    <div className="mt-4">
      <h3 className="flex justify-between text-xl font-bold text-primary">
        Experience
        <div className="flex space-x-4">
          <button onClick={onAdd} className=" hover:bg-gray-200">
            <MdOutlineAddCircleOutline className="w-6 h-6" />
          </button>
          {/* <button onClick={onEdit} className="mr-2 hover:bg-gray-200 ">
            <MdOutlineEdit className="w-6 h-6" />
          </button> */}
        </div>
      </h3>
      <ul>
        {experience.map((exp, index) => (
          <li
            key={exp.id}
            className={`flex flex-col justify-start text-sm px-4 pb-2 rounded-lg ${
              index % 2 === 0 ? "bg-gray-100" : "bg-white"
            }`}
          >
            <div className="pt-4">
              <span className="font-semibold pr-2">{exp.job_title}</span>
            </div>
            <div>{exp.company}</div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ExperienceSection;
