import React, { useState } from "react";

function FAQs() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="py-20 min-h-lvh">
      <div className=" my-16 mx-16 bg-primary rounded-xl  shadow-lg shadow-slate-400 flex flex-col items-center justify-center relative">
        <div className="absolute inset-0 bg-gray-700 opacity-40 rounded-xl transform -rotate-3"></div>
        <h1 className="text-2xl mt-6 md:text-5xl font-bold text-white  text-center mb-6 relative z-10">
          FAQ
        </h1>
      </div>
      <ul className="space-y-3 w-4/5 mx-8 md:mx-16">
        {faqData.map((faq, index) => (
          <li key={index}>
            <button
              className="text-left w-full p-3 pl-4 bg-stone-200 text-priamry md:text-lg font-medium rounded-md hover:bg-stone-300 transition duration-300"
              onClick={() => toggleAnswer(index)}
            >
              {faq.question}
            </button>
            {openIndex === index && (
              <p className="mt-2 p-2 pl-4 bg-gray-200 rounded-md">
                {faq.answer}
              </p>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

const faqData = [
  {
    question: "What is Unibridge?",
    answer:
      "Unibridge is a student-mentor platform designed to provide comprehensive, end-to-end support for students seeking higher education abroad. Whether you're aiming for undergraduate or postgraduate studies, the platform connects you with experienced mentors who have successfully navigated the foreign university application process. From selecting the right course and university to crafting compelling Statements of Purpose (SOPs) and preparing for interviews, our mentors guide you through every step, ensuring you have the best chance of securing admission to your dream university. Additionally, we assist with scholarship applications, financial planning, and offer personalized feedback to help you succeed.",
  },
  {
    question:
      "Does Unibridge help students with applications to all universites globally?",
    answer:
      "Unibridge's mentors are from world renowned top institutions who have themselves gone through the application process and successfully secured admissions. While we try to match you with a mentor from your preferred universities, our mentors are fully equipped to guide you in your applications for all global universities.",
  },
  {
    question: "Will Unibridge help me secure scholarships for my education?",
    answer:
      "All foreign universities have multiple scholarships for students. While most of these scholarships are applicable for Masters level and above, our mentors will support you in preparing SOPs for scholarships wherever applicable. We also have tie ups with leading financial institutions who can provide loans at highly attractive interest rates, exclusively for the Uni-bridge platform",
  },
  {
    question:
      "Does Uni-bridge help in preparation for IELTS/TOEFL.GRE/GMAT exams as well?",
    answer:
      "Uni-bridge mentors can give you tips to get high scores in these exams. We can also connect you with our chosen institutions who provide the neceaasry coaching for these exams along with adaptive and specific mock tests ensuring that you have are fully prepared for these exams",
  },
  {
    question: "Which Course Should I Pursue, and at Which University?",
    answer:
      "Certainly! Deciding what to do may sound daunting at first, but it is critical to identify which courses and universities to include in your basket. Choosing the right course and university is a pivotal decision that significantly shapes your academic and professional journey. With our personalized approach, our seasoned mentors evaluate your academic strengths, interests, and career ambitions. Based on this assessment, we recommend suitable courses aligned with your objectives. We also guide you toward leading universities renowned for their excellent programs in your chosen discipline. Armed with comprehensive insights into admission criteria, campus environment, and prospects, you’ll make an informed choice that sets you up for success. Remember, this process is like assembling the pieces of a puzzle. Each decision you make brings you closer to your future goals. If you have any more questions or need further assistance, feel free to ask! 😊",
  },
  {
    question:
      "I find the application process very complex. How do I approach filing applications?",
    answer:
      "International universities employ a holistic approach to evaluating applicants, carefully considering academic accomplishments, extracurricular activities, and a demonstrated passion for the chosen field of study. This process often involves submitting comprehensive personal statements and cover letters, where candidates must clearly articulate their motivations, experiences, and future goals. At Unibridge, our team of experienced mentors is committed to supporting you through every step of this demanding process. We specialise in crafting compelling applications highlighting your distinct experiences and aligning your goals with your target universities’ core values and strengths.",
  },
  {
    question: "How will I fund my education and living expenses?",
    answer:
      "We recognise that studying at a foreign university can be expensive, and not everyone has the financial resources to afford it. However, many scholarships are available at universities worldwide, offering substantial financial support to deserving students. These scholarships may cover tuition fees and provide stipends to help manage living expenses. Our dedicated mentors offer personalised guidance in identifying and applying for these scholarships, helping you enhance your chances of securing financial aid. In addition to scholarships, we have partnered with leading banks to provide exclusive education loan options at highly competitive interest rates, available exclusively through our platform.",
  },
  {
    question:
      "Will I be able to secure a job post-study and get an employment visa?",
    answer:
      "Strict visa regulations and uncertainty about post-study work opportunities often deter students from pursuing international education. However, most countries provide post-study work visas that grant sufficient time for graduates to explore career opportunities, many of which can lead to long-term employment. Here’s an overview of key regions and their post-study visa options: United Kingdom: Students are eligible for a post-study work visa lasting 2 to 3 years, depending on the course they complete. Europe: Many European countries offer students the opportunity to work post-graduation without needing employer sponsorship, as long as they have a valid job offer. United States: Graduates in the US can apply for Optional Practical Training (OPT), which allows them to work for 12 to 36 months post-graduation, depending on their field of study.",
  },
  {
    question:
      "Will I survive homesickness in a foreign country where I do not know anyone?",
    answer:
      "Being away from home for an extended period can be emotionally challenging, and the lack of immediate support from family and friends may cause anxiety for some students. However, our research shows that major global institutions have strong and vibrant Indian communities that provide a sense of familiarity and connection. Upon admission, universities often organise orientation programs and fresher events, allowing students to connect with peers worldwide, including many from India. To further ease the transition, we strive to connect you with other students joining the same course or university during your academic year.",
  },
];

export default FAQs;
