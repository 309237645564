import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
import { FcGoogle } from "react-icons/fc";
import swal from "sweetalert2";
function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();

    if (!email || !password || !confirmPassword) {
      swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in all the fields.",
      });
      return;
    }

    if (password !== confirmPassword) {
      swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Passwords do not match.",
      });
      return;
    }

    try {
      const { data: user, error } = await supabase.auth.signUp({
        email,
        password,
      });

      if (error) {
        console.error("Signup error:", error.message);
        swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Signup failed. Please try again.",
        });
      } else {
        swal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully registered",
        });
        navigate("/signupfilter");
      }
    } catch (err) {
      console.error("An unexpected error occurred:", err);
    }
  };

  const handleGoogleLogin = async (e) => {
    e.preventDefault();
    let { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
    });
  };

  return (
    <div className="pt-16 bg-stone-100">
      <div className="md:w-3/5 flex items-center justify-center h-lvh mx-auto bg-white">
        <div className="w-full px-8 max-w-md">
          <h2 className="text-3xl font-semibold my-2">Sign Up</h2>
          <div className="text-start mt-1">
            Already have an account? Click here for{" "}
            <Link
              to="/login"
              className=" font-bold text-secondary-100 hover:text-blue-500"
            >
              login.
            </Link>
          </div>
          <div className="border border-spacing-0 border-secondary-200 opacity-40 mb-6"></div>
          <form onSubmit={handleSignup}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-semibold text-gray-700 mb-2"
              >
                EMAIL
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email@address.com"
                className="mt-1 block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-semibold text-gray-700 mb-2"
              >
                PASSWORD
              </label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="**********"
                className="mt-1 block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className=" pr-3 flex items-center text-sm leading-5"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <div className="mb-6">
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-semibold text-gray-700 mb-2"
              >
                CONFIRM PASSWORD
              </label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="**********"
                className="mt-1 block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              {confirmPassword !== password && confirmPassword && (
                <div className="text-red-500 text-sm mt-2">
                  Passwords do not match.
                </div>
              )}
            </div>

            <button
              type="submit"
              className="w-full bg-secondary-100 text-white px-4 py-2 rounded-md hover:scale-105 transition duration-300"
            >
              Sign Up
            </button>
            <div className="flex justify-center">or Connect using</div>
            <div className="flex justify-center space-x-4 mt-2">
              <button
                onClick={handleGoogleLogin}
                className="w-10 h-10 flex items-center justify-center rounded-full border border-gray-300 shadow-sm"
              >
                <FcGoogle />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signup;
