import React from "react";
import supabase from "../../config/supabaseClient";
import swal from "sweetalert2";

function EditAboutModal({
  userId,
  formData,
  setFormData,
  handleChange,
  onClose,
  onSave,
}) {
  const updateAboutMe = async () => {
    const { error } = await supabase
      .from("users")
      .update({ about_yourself: formData.About_yourself })
      .eq("user_id", userId);
    if (error) {
      console.error("Error updating about me:", error);
    } else {
      swal.fire({
        title: "Success",
        text: "About me updated successfully, refresh the page to see the changes",
        icon: "success",
      });
      onSave();
    }
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 pt-28 pb-8">
      <div className="bg-white p-4 rounded shadow-lg md:w-1/2 mx-4 overflow-y-auto h-[70vh]">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold text-primary mr-6">Edit About Me</h2>
          <button
            onClick={onClose}
            className="bg-secondary-500 text-white px-4 py-2 rounded-md"
          >
            Close
          </button>
        </div>
        <textarea
          placeholder="About Me"
          name="About_yourself"
          value={formData.About_yourself}
          onChange={handleChange}
          className="w-full h-40 p-2 border rounded mt-8"
        />
        <div className="flex justify-end">
          <button
            onClick={updateAboutMe}
            className="bg-primary text-white px-4 py-2 my-4 rounded-md"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditAboutModal;
